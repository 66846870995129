<template>
  <div class="category-tree">
    <a-spin :loading="loading">
      <a-tree
        :data="treeData"
        :draggable="true"
        :show-line="true"
        :selected-keys="selectedKeys"
        @select="handleSelect"
        @drop="handleDrop"
      >
        <template #title="nodeData">
          <div class="tree-node">
            <span class="node-title">{{ nodeData.title }}</span>
            <div class="node-actions">
              <a-button-group size="mini">
                <a-button @click.stop="handleAdd(nodeData)">添加</a-button>
                <a-button @click.stop="handleEdit(nodeData)">编辑</a-button>
                <a-button @click.stop="handleDelete(nodeData)" status="danger">
                  删除
                </a-button>
                <a-dropdown @select="handleMove($event, nodeData)">
                  <a-button>移动</a-button>
                  <template #content>
                    <a-doption value="up">上移</a-doption>
                    <a-doption value="down">下移</a-doption>
                  </template>
                </a-dropdown>
              </a-button-group>
            </div>
          </div>
        </template>
      </a-tree>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  onMounted,
  watch,
  defineProps,
  defineEmits,
  defineExpose,
  withDefaults
} from 'vue'
import { Message, Modal } from '@arco-design/web-vue'
import { ProductCategoryControllerService } from '../../../../generated'
import type { CategoryTreeVO } from '../../../../generated/models/CategoryTreeVO'

interface Props {
  selectedId?: number
}

const props = withDefaults(defineProps<Props>(), {
  selectedId: undefined
})

const emit = defineEmits<{
  (e: 'update:selectedId', id: number): void
  (e: 'node-click', node: CategoryTreeVO): void
  (e: 'add', node: CategoryTreeVO): void
  (e: 'edit', node: CategoryTreeVO): void
}>()

// 树形数据
const treeData = ref<CategoryTreeVO[]>([])
const loading = ref(false)
const selectedKeys = ref<string[]>([])

// 监听选中ID变化
watch(
  () => props.selectedId,
  (newId) => {
    if (newId) {
      selectedKeys.value = [String(newId)]
    } else {
      selectedKeys.value = []
    }
  }
)

// 加载分类树数据
const loadCategoryTree = async () => {
  loading.value = true
  try {
    const response =
      await ProductCategoryControllerService.getCategoryTreeUsingGet()
    if (response.data) {
      // 转换数据格式
      treeData.value = convertToTreeData(response.data)
    }
  } catch (error) {
    console.error('加载分类树失败:', error)
    Message.error('加载分类树失败')
  } finally {
    loading.value = false
  }
}

// 转换数据为树形格式
const convertToTreeData = (categories: CategoryTreeVO[]): any[] => {
  return categories.map((category) => ({
    key: category.id,
    title: category.name,
    children: category.children ? convertToTreeData(category.children) : [],
    dataRef: category
  }))
}

// 处理节点选择
const handleSelect = (selectedKeys: string[], node: any) => {
  if (selectedKeys.length > 0 && node.selectedNodes?.[0]) {
    const id = Number(selectedKeys[0])
    const nodeData = node.selectedNodes[0].dataRef
    emit('update:selectedId', id)
    emit('node-click', nodeData)
  }
}

// 处理添加子分类
const handleAdd = (node: any) => {
  if (node.dataRef) {
    emit('add', node.dataRef)
  }
}

// 处理编辑分类
const handleEdit = (node: any) => {
  if (node.dataRef) {
    emit('edit', node.dataRef)
  }
}

// 处理删除分类
const handleDelete = async (node: any) => {
  if (!node.dataRef?.id) return

  Modal.confirm({
    title: '确认删除',
    content: `确定要删除分类"${node.title}"吗？删除后不可恢复。`,
    onOk: async () => {
      try {
        const response =
          await ProductCategoryControllerService.deleteCategoryUsingPost({
            id: node.dataRef.id
          })
        if (response.data) {
          Message.success('删除成功')
          await loadCategoryTree()
        }
      } catch (error) {
        console.error('删除分类失败:', error)
        Message.error('删除分类失败')
      }
    }
  })
}

// 处理移动分类
const handleMove = async (direction: string, node: any) => {
  if (!node.dataRef?.id) return

  try {
    const response =
      await ProductCategoryControllerService.swapCategorySortUsingPost(
        node.dataRef.id,
        direction
      )
    if (response.data) {
      Message.success('移动成功')
      await loadCategoryTree()
    }
  } catch (error) {
    console.error('移动分类失败:', error)
    Message.error('移动分类失败')
  }
}

// 处理拖拽
const handleDrop = async (info: any) => {
  const dropNode = info.node
  const dragNode = info.dragNode

  if (!dropNode.dataRef?.id || !dragNode.dataRef?.id) return

  // 检查是否超过最大层级
  const getLevel = (node: any): number => {
    let level = 0
    let current = node
    while (current.parent) {
      level++
      current = current.parent
    }
    return level
  }

  if (getLevel(dropNode) >= 3) {
    Message.error('不能超过3级分类')
    return
  }

  try {
    const response =
      await ProductCategoryControllerService.moveCategoriesUsingPost({
        categoryIds: [dragNode.dataRef.id],
        targetParentId: dropNode.dataRef.id
      })
    if (response.data) {
      Message.success('移动成功')
      await loadCategoryTree()
    }
  } catch (error) {
    console.error('移动分类失败:', error)
    Message.error('移动分类失败')
  }
}

// 暴露刷新方法
defineExpose({
  refreshTree: loadCategoryTree
})

// 初始加载
onMounted(() => {
  loadCategoryTree()
})
</script>

<style scoped>
.category-tree {
  height: 100%;
}

.tree-node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

.node-title {
  margin-right: 8px;
}

.node-actions {
  opacity: 0;
  transition: opacity 0.2s;
}

.tree-node:hover .node-actions {
  opacity: 1;
}
</style>
