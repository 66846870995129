<template>
  <a-space :style="{ width: '100%' }" direction="vertical">
    <a-upload
      :file-list="file ? [file] : []"
      @change="handleChange"
      @progress="handleProgress"
    >
      <template #upload-button>
        <div
          :class="`arco-upload-list-item${
            file?.status === 'error' ? ' arco-upload-list-item-error' : ''
          }`"
        >
          <div
            v-if="props.value"
            class="arco-upload-list-picture custom-upload-avatar"
          >
            <img :src="props.value" alt="avatar preview" />
            <div class="arco-upload-list-picture-mask">
              <IconEdit />
            </div>
            <a-progress
              v-if="file.status === 'uploading' && file.percent < 100"
              :percent="file.percent"
              :style="progressStyle"
              size="mini"
              type="circle"
            />
          </div>
          <div v-else class="arco-upload-picture-card">
            <div class="arco-upload-picture-card-text">
              <IconPlus />
              <div class="upload-text">Upload</div>
            </div>
          </div>
        </div>
      </template>
    </a-upload>
  </a-space>
</template>

<script lang="ts" setup>
import { ref, watch, defineProps, defineEmits } from 'vue'
import { IconEdit, IconPlus } from '@arco-design/web-vue/es/icon'
import type { FileItem } from '@arco-design/web-vue'
import { FileControllerService } from '../../generated/services/FileControllerService'

// 响应式状态
const props = defineProps<{
  value: FileItem | null // 接收父组件传入的文件
}>()
const emit = defineEmits<{
  (e: 'change', value: FileItem | null): void
}>()

const file = ref<FileItem | null>(props.value)
const progressStyle = ref({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)'
})

// 事件处理
const handleChange = async (_: unknown, currentFile: FileItem) => {
  file.value = {
    ...currentFile,
    url: URL.createObjectURL(currentFile.file as Blob)
  }

  // 上传文件
  try {
    const response = await FileControllerService.uploadFileUsingPost(
      currentFile.file as Blob,
      'cover'
    )
    // 假设 response.data.url 是返回的图片 URL
    emit('change', response.data.url) // 将 URL 传递给父组件
    console.log('文件上传成功:', response)
  } catch (error) {
    console.error('文件上传失败:', error)
    emit('change', null) // 上传失败时清空文件
  }
}

const handleProgress = (currentFile: FileItem) => {
  file.value = currentFile
}

// 监听 props 的变化
watch(
  () => props.value,
  (newValue) => {
    file.value = newValue
  }
)
</script>

<style scoped>
.upload-text {
  margin-top: 10px;
  font-weight: 600;
}
</style>
