<template>
  <div id="addProductView">
    <h1>添加产品</h1>

    <a-form :model="form" label-align="left">
      <a-form-item field="name" label="产品名字">
        <a-input v-model="form.name" placeholder="请输入产品名字" />
      </a-form-item>

      <a-form-item field="picture" label="产品封面">
        <ImageUploader :value="form.picture" @change="handleImageChange" />
      </a-form-item>
      <a-form-item field="imageUrls" label="产品详细图片">
        <a-upload
          list-type="picture-card"
          :file-list="imageFileList"
          :custom-request="handleCustomUpload"
          @change="handleDetailImagesChange"
          multiple
          :auto-upload="true"
          :accept="'image/*'"
          action="/"
        >
          <div class="arco-upload-picture-card">
            <div class="arco-upload-picture-card-text">
              <icon-plus />
              <div class="upload-text">上传图片</div>
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item field="tags" label="标签">
        <a-input-tag
          v-model="form.tags"
          :style="{ width: '320px' }"
          allow-clear
          placeholder="请输入产品的标签"
        />
      </a-form-item>
      <a-form-item field="content" label="产品介绍">
        <MdEditor :handle-change="onContentChange" :value="form.content" />
      </a-form-item>
      <a-form-item field="taobao_link" label="产品链接">
        <a-input
          v-model="form.taobao_link"
          placeholder="请输入产品的淘宝的链接"
        />
      </a-form-item>
      <div style="margin-top: 16px" />
      <a-form-item>
        <a-button style="min-width: 200px" type="primary" @click="doSubmit"
          >提交</a-button
        >
      </a-form-item>
    </a-form>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import MdEditor from '@/components/MdEditor.vue'
import { ProductInfoAddRequest } from '../../../generated' // 假设你的 API 方法在这个路径下
import ImageUploader from '@/components/ImageUploader.vue'
import { ProductInfoControllerService } from '../../../generated/services/ProductInfoControllerService'
import { Message } from '@arco-design/web-vue'
import { IconPlus } from '@arco-design/web-vue/es/icon'
import type { FileItem } from '@arco-design/web-vue'
import { FileControllerService } from '../../../generated/services/FileControllerService'

const form = ref<ProductInfoAddRequest>({
  content: '',
  imageUrls: [],
  name: '',
  picture: '',
  tags: [],
  taobao_link: ''
})

const onContentChange = (value: string) => {
  form.value.content = value
}

// 处理图片变化
const handleImageChange = async (url: string | null) => {
  if (url) {
    form.value.picture = url // 更新表单中的图片 URL
    console.log('表单中的picture数据', form.value.picture)
  } else {
    form.value.picture = '' // 清空图片 URL
  }
}

// 添加图片文件列表的响应式引用
const imageFileList = ref<FileItem[]>([])

// 修改自定义上传方法
const handleCustomUpload = async (option: {
  fileItem: FileItem
  action: string
  name?: string
  data?: Record<string, any>
  headers?: Record<string, string>
  onSuccess: (response: any) => void
  onError: (error: any) => void
  onProgress: (percent: number) => void
}) => {
  try {
    option.onProgress(0) // 开始上传时设置进度
    console.log('开始上传文件:', option.fileItem)

    // 确保文件是有效的
    if (!option.fileItem?.file) {
      throw new Error('无效的文件')
    }

    const response = await FileControllerService.uploadFileUsingPost(
      option.fileItem.file as File,
      'detail'
    )
    console.log('上传响应:', response)

    if (response.code === 0 && response.data?.url) {
      option.onProgress(100) // 上传完成
      option.onSuccess({
        code: 0,
        data: {
          url: response.data.url
        }
      })
      Message.success('图片上传成功')
    } else {
      const errorMsg = response.message || '上传失败'
      console.error('上传失败:', errorMsg)
      option.onError(new Error(errorMsg))
      Message.error(`图片上传失败: ${errorMsg}`)
    }
  } catch (error) {
    console.error('上传过程出错:', error)
    option.onError(error)
    Message.error(error instanceof Error ? error.message : '图片上传失败')
  }
}

// 修改文件变化处理方法
const handleDetailImagesChange = (
  fileList: FileItem[],
  currentFile: FileItem
) => {
  console.log('文件列表变化:', fileList)
  console.log('当前文件:', currentFile)

  imageFileList.value = fileList

  // 更新表单中的 URLs
  const urls = fileList
    .filter((file) => file.status === 'done' && file.response?.data?.url)
    .map((file) => file.response.data.url)

  console.log('提取的URLs:', urls)
  form.value.imageUrls = urls
}

// 定义 doSubmit 方法
const doSubmit = async () => {
  try {
    const response = await ProductInfoControllerService.addProductInfoUsingPost(
      form.value
    )
    if (response.code === 0) {
      Message.success('产品添加成功')
      // 可以在这里重置表单或进行其他操作
    } else {
      Message.error('产品添加失败: ' + response.message)
    }
  } catch (error) {
    console.error('提交产品信息失败:', error)
    Message.error('提交产品信息失败')
  }
}
</script>

<style scoped>
#addProductView {
  max-width: 600px;
  margin: auto;
}

.image-wrapper:hover .arco-upload-list-picture-mask {
  opacity: 1; /* 鼠标悬停时显示编辑按钮 */
}

/* 添加上传按钮样式 */
.upload-text {
  margin-top: 10px;
  font-size: 14px;
  color: var(--color-text-2);
}
</style>
