<template>
  <a-modal
    :visible="visible"
    :title="editData ? '编辑分类' : '添加分类'"
    @cancel="handleCancel"
    @ok="handleSubmit"
    @close="handleClose"
  >
    <a-form ref="formRef" :model="formData" :rules="rules">
      <a-form-item field="name" label="分类名称" required>
        <a-input v-model="formData.name" placeholder="请输入分类名称" />
      </a-form-item>
      <a-form-item field="sort" label="排序">
        <a-input-number v-model="formData.sort" placeholder="请输入排序值" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits, withDefaults } from 'vue'
import { Message } from '@arco-design/web-vue'
import type { FormInstance } from '@arco-design/web-vue'
import { ProductCategoryControllerService } from '../../../../generated'
import type { CategoryAddRequest } from '../../../../generated/models/CategoryAddRequest'
import type { CategoryUpdateRequest } from '../../../../generated/models/CategoryUpdateRequest'
import type { CategoryTreeVO } from '../../../../generated/models/CategoryTreeVO'

interface Props {
  visible: boolean
  parentId?: number
  editData?: CategoryTreeVO
}

const props = withDefaults(defineProps<Props>(), {
  parentId: undefined,
  editData: undefined
})

const emit = defineEmits<{
  (e: 'update:visible', visible: boolean): void
  (e: 'success'): void
}>()

// 表单引用
const formRef = ref<FormInstance>()

// 表单数据
const formData = ref({
  name: '',
  sort: 0
})

// 表单校验规则
const rules = {
  name: [
    { required: true, message: '请输入分类名称' },
    { maxLength: 50, message: '分类名称最多50个字符' }
  ],
  sort: [{ type: 'number', message: '请输入有效的排序值' }]
}

// 监听编辑数据变化
watch(
  () => props.editData,
  (newData) => {
    if (newData) {
      formData.value = {
        name: newData.name || '',
        sort: 0
      }
    } else {
      formData.value = {
        name: '',
        sort: 0
      }
    }
  },
  { immediate: true }
)

// 处理取消
const handleCancel = () => {
  emit('update:visible', false)
}

// 处理关闭
const handleClose = () => {
  emit('update:visible', false)
}

// 处理提交
const handleSubmit = async () => {
  if (!formRef.value) return

  try {
    await formRef.value.validate()

    if (props.editData) {
      // 编辑分类
      const updateData: CategoryUpdateRequest = {
        id: props.editData.id || 0,
        name: formData.value.name,
        sort: formData.value.sort
      }

      const response =
        await ProductCategoryControllerService.updateCategoryUsingPost(
          updateData
        )

      if (response.data) {
        Message.success('编辑成功')
        emit('success')
        emit('update:visible', false)
      }
    } else {
      // 添加分类
      const addData: CategoryAddRequest = {
        name: formData.value.name,
        parent_id: props.parentId || 0,
        sort: formData.value.sort
      }

      const response =
        await ProductCategoryControllerService.addCategoryUsingPost(addData)

      if (response.data) {
        Message.success('添加成功')
        emit('success')
        emit('update:visible', false)
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      Message.error(error.message)
    } else {
      console.error('提交失败:', error)
      Message.error('操作失败')
    }
  }
}
</script>
