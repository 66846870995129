import router from '@/router'
import store from '@/store'
import ACCESS_ENUM from '@/access/accessEnum'
import checkAccess from '@/access/checkAccess'

// 路由跳转的 权限的判断
router.beforeEach(async (to, from, next) => {
  console.log(to)
  const loginUser = store.state.user.loginUser
  console.log('登录的用户信息', loginUser)
  // 自动的登录
  if (!loginUser || !loginUser.userRole) {
    await store.dispatch('user/getLoginUser')
  }
  //定义页面需要的权限
  const needAccess = (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN
  if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
    //!loginUser || !loginUser.userRole 这是没有登录的条件
    if (
      !loginUser ||
      !loginUser.userRole ||
      loginUser.userRole === ACCESS_ENUM.NOT_LOGIN
    ) {
      next(`/user/login?redirect=${to.fullPath}`)
      return
    }
    if (!checkAccess(loginUser, needAccess)) {
      next('/notAuth')
      return
    }
  }
  next()
})
