/**
 * 权限定义 的枚举类
 */
const ACCESS_ENUM = {
  NOT_LOGIN: 'notLogin',
  USER: 'user',
  ADMIN: 'admin'
}

export default ACCESS_ENUM
