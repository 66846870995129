<template>
  <div class="add-material">
    <a-card title="添加资料">
      <a-form :model="form" @submit="handleSubmit">
        <a-form-item field="name" label="资料名称" required>
          <a-input v-model="form.name" placeholder="请输入资料名称" />
        </a-form-item>

        <a-form-item field="description" label="资料描述" required>
          <a-textarea
            v-model="form.description"
            placeholder="请输入资料描述"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>

        <a-form-item field="fileType" label="文件类型" required>
          <a-select v-model="form.fileType" placeholder="请选择文件类型">
            <a-option value="PDF">PDF</a-option>
            <a-option value="WORD">WORD</a-option>
            <a-option value="EXCEL">EXCEL</a-option>
          </a-select>
        </a-form-item>

        <a-form-item field="file" label="上传文件" required>
          <fileUploader
            v-model="form.file"
            accept=".pdf,.doc,.docx,.xls,.xlsx"
            @update:modelValue="handleFileChange"
          />
          <div v-if="form.file" class="file-info">
            <icon-check-circle-fill class="success-icon" />
            <span>文件已上传成功</span>
          </div>
        </a-form-item>

        <a-form-item>
          <a-space>
            <a-button type="primary" html-type="submit" :loading="loading">
              提交
            </a-button>
            <a-button @click="handleCancel">取消</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Message } from '@arco-design/web-vue'
import { IconCheckCircleFill } from '@arco-design/web-vue/es/icon'
import { ProductMaterialControllerService } from '../../../generated/services/ProductMaterialControllerService'
import fileUploader from '@/components/fileUploader.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const loading = ref(false)
const fileUploaded = ref(false)

interface FormState {
  name: string
  description: string
  fileType: string
  file: string
}

const form = ref<FormState>({
  name: '',
  description: '',
  fileType: '',
  file: ''
})

// 处理文件变化
const handleFileChange = (url: string) => {
  fileUploaded.value = !!url
  console.log('文件状态变化:', url ? '已上传' : '已删除')
}

// 取消操作
const handleCancel = () => {
  router.back()
}

const handleSubmit = async () => {
  if (
    !form.value.name ||
    !form.value.description ||
    !form.value.fileType ||
    !form.value.file
  ) {
    Message.error('请填写完整信息')
    return
  }

  try {
    loading.value = true
    const res =
      await ProductMaterialControllerService.addProductMaterialUsingPost({
        name: form.value.name,
        description: form.value.description,
        file_type: form.value.fileType,
        file_url: form.value.file
      })

    if (res.code === 0) {
      Message.success('添加成功')
      router.push('/materials')
    } else {
      Message.error(res.message || '添加失败')
    }
  } catch (error) {
    console.error('提交失败:', error)
    Message.error('系统错误')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
.add-material {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.file-info {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-success-6);
}

.success-icon {
  color: var(--color-success-6);
}
</style>
