/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_'
import type { BaseResponse_long_ } from '../models/BaseResponse_long_'
import type { BaseResponse_Page_VisitRecordVO_ } from '../models/BaseResponse_Page_VisitRecordVO_'
import type { BaseResponse_VisitStatisticsVO_ } from '../models/BaseResponse_VisitStatisticsVO_'
import type { VisitRecord } from '../models/VisitRecord'
import type { VisitRecordQueryRequest } from '../models/VisitRecordQueryRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class VisitRecordControllerService {
  /**
   * listVisitRecordByPage
   * @param visitRecordQueryRequest visitRecordQueryRequest
   * @returns BaseResponse_Page_VisitRecordVO_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static listVisitRecordByPageUsingPost(
    visitRecordQueryRequest: VisitRecordQueryRequest
  ): CancelablePromise<BaseResponse_Page_VisitRecordVO_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/visits/list/page',
      body: visitRecordQueryRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * recordVisit
   * @param visitRecord visitRecord
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static recordVisitUsingPost(
    visitRecord: VisitRecord
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/visits/record',
      body: visitRecord,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * getStatistics
   * @returns BaseResponse_VisitStatisticsVO_ OK
   * @throws ApiError
   */
  public static getStatisticsUsingGet(): CancelablePromise<BaseResponse_VisitStatisticsVO_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/visits/statistics',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * getTodayVisits
   * @returns BaseResponse_long_ OK
   * @throws ApiError
   */
  public static getTodayVisitsUsingGet(): CancelablePromise<BaseResponse_long_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/visits/today',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * getTotalVisits
   * @returns BaseResponse_long_ OK
   * @throws ApiError
   */
  public static getTotalVisitsUsingGet(): CancelablePromise<BaseResponse_long_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/visits/total',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
}
