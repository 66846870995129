<template>
  <div class="category-manage-container">
    <a-row :gutter="16">
      <!-- 左侧分类树 -->
      <a-col :span="8">
        <a-card class="category-tree-card" title="分类树">
          <template #extra>
            <a-button type="primary" @click="handleAddRootCategory">
              添加根分类
            </a-button>
          </template>
          <category-tree
            ref="categoryTreeRef"
            v-model:selected-id="selectedCategoryId"
            @node-click="handleNodeClick"
            @add="handleAdd"
            @edit="handleEdit"
          />
        </a-card>
      </a-col>

      <!-- 右侧内容区 -->
      <a-col :span="16">
        <category-detail
          v-if="selectedCategoryId"
          :category-id="selectedCategoryId"
          @refresh-tree="refreshCategoryTree"
        />
        <a-empty v-else description="请选择分类" />
      </a-col>
    </a-row>

    <!-- 添加/编辑分类弹窗 -->
    <category-form-modal
      v-model:visible="categoryFormVisible"
      :parent-id="categoryFormParentId"
      :edit-data="categoryFormEditData"
      @success="handleFormSuccess"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Message } from '@arco-design/web-vue'
import CategoryTree from './components/CategoryTree.vue'
import CategoryDetail from './components/CategoryDetail.vue'
import CategoryFormModal from './components/CategoryFormModal.vue'
import type { CategoryTreeVO } from '../../../generated/models/CategoryTreeVO'

// 当前选中的分类ID
const selectedCategoryId = ref<number>()

// 分类树组件引用
const categoryTreeRef = ref()

// 分类表单弹窗控制
const categoryFormVisible = ref(false)
const categoryFormParentId = ref<number>()
const categoryFormEditData = ref<CategoryTreeVO | null>(null)

// 添加根分类
const handleAddRootCategory = () => {
  categoryFormParentId.value = 0
  categoryFormEditData.value = null
  categoryFormVisible.value = true
}

// 处理节点点击
const handleNodeClick = (nodeData: CategoryTreeVO) => {
  selectedCategoryId.value = nodeData.id
}

// 处理添加子分类
const handleAdd = (node: CategoryTreeVO) => {
  categoryFormParentId.value = node.id
  categoryFormEditData.value = null
  categoryFormVisible.value = true
}

// 处理编辑分类
const handleEdit = (node: CategoryTreeVO) => {
  categoryFormParentId.value = node.id
  categoryFormEditData.value = node
  categoryFormVisible.value = true
}

// 刷新分类树
const refreshCategoryTree = () => {
  categoryTreeRef.value?.refreshTree()
}

// 处理表单提交成功
const handleFormSuccess = () => {
  Message.success('操作成功')
  refreshCategoryTree()
  categoryFormVisible.value = false
}
</script>

<style scoped>
.category-manage-container {
  padding: 20px;
}

.category-tree-card {
  min-height: 600px;
}
</style>
