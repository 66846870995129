<template>
  <div class="contact-container">
    <div class="contact-content">
      <!-- 标题部分 -->
      <div class="title-section">
        <h1 class="main-title">联系我们</h1>
        <div class="breadcrumb">
          <a-breadcrumb>
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>联系我们</a-breadcrumb-item>
            <a-breadcrumb-item>联系方式</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </div>

      <!-- 公司信息部分 -->
      <a-spin :loading="loading">
        <div class="company-info" v-if="companyInfo">
          <h2 class="company-name">
            {{ companyInfo.company_name || '公司名称' }}
          </h2>

          <div class="info-grid">
            <!-- 服务热线 -->
            <div class="info-item" v-if="companyInfo.phone">
              <div class="label">服务热线：</div>
              <div class="value highlight">{{ companyInfo.phone }}</div>
            </div>

            <!-- 电话 -->
            <div class="info-item" v-if="companyInfo.phone">
              <div class="label">电话：</div>
              <div class="value">{{ companyInfo.phone }}</div>
            </div>

            <!-- 邮箱 -->
            <div class="info-item" v-if="companyInfo.email">
              <div class="label">邮箱：</div>
              <div class="value">{{ companyInfo.email }}</div>
            </div>

            <!-- 地址 -->
            <div class="info-item" v-if="companyInfo.address">
              <div class="label">地址：</div>
              <div class="value">{{ companyInfo.address }}</div>
            </div>

            <!-- 生产基地 -->
            <div class="info-item" v-if="companyInfo.production_base">
              <div class="label">生产基地：</div>
              <div class="value">{{ companyInfo.production_base }}</div>
            </div>

            <!-- 微信 -->
            <div class="info-item" v-if="companyInfo.wechat">
              <div class="label">微信：</div>
              <div class="value">{{ companyInfo.wechat }}</div>
            </div>
          </div>

          <!-- 二维码展示 -->
          <div class="qrcode-section" v-if="companyInfo.wechat_qrcode">
            <h3>扫码联系我们</h3>
            <div class="qrcode-container">
              <img
                :src="companyInfo.wechat_qrcode"
                alt="微信二维码"
                class="qrcode-image"
              />
              <div class="qrcode-text">微信咨询</div>
            </div>
          </div>
        </div>
        <div class="empty-info" v-else>
          <a-empty description="暂无公司联系信息" />
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { Message } from '@arco-design/web-vue'
import { CompanyInfoControllerService } from '../../../generated'
import type { CompanyInfoVo } from '../../../generated'

// 公司信息
const companyInfo = ref<CompanyInfoVo | null>(null)
const loading = ref(false)

// 获取公司信息
const fetchCompanyInfo = async () => {
  loading.value = true
  try {
    const response = await CompanyInfoControllerService.getCompanyInfoUsingGet()
    if (response.data) {
      companyInfo.value = response.data
    }
  } catch (error) {
    console.error('获取公司信息失败:', error)
    Message.error('获取公司信息失败')
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchCompanyInfo()
})
</script>

<style scoped>
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.contact-content {
  background: var(--color-bg-2);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.title-section {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--color-border-2);
  padding-bottom: 16px;
}

.main-title {
  font-size: 24px;
  color: var(--color-text-1);
  margin-bottom: 16px;
}

.breadcrumb {
  color: var(--color-text-3);
}

.company-info {
  padding: 24px 0;
}

.company-name {
  font-size: 20px;
  color: var(--color-text-1);
  margin-bottom: 32px;
  font-weight: 500;
}

.info-grid {
  display: grid;
  gap: 24px;
}

.info-item {
  display: flex;
  align-items: flex-start;
}

.label {
  min-width: 100px;
  color: var(--color-text-2);
  font-weight: 500;
}

.value {
  flex: 1;
  color: var(--color-text-1);
  line-height: 1.6;
}

.highlight {
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
}

.qrcode-section {
  margin-top: 40px;
  text-align: center;
}

.qrcode-section h3 {
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--color-text-1);
}

.qrcode-container {
  display: inline-block;
}

.qrcode-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
  border: 1px solid var(--color-border-2);
  padding: 10px;
  background-color: white;
}

.qrcode-text {
  margin-top: 10px;
  color: var(--color-text-2);
}

.empty-info {
  padding: 40px 0;
  text-align: center;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .contact-content {
    padding: 20px;
  }

  .info-item {
    flex-direction: column;
  }

  .label {
    margin-bottom: 8px;
  }

  .qrcode-image {
    width: 150px;
    height: 150px;
  }
}
</style>
