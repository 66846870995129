<template>
  <div>
    <template v-if="route.path.startsWith('/user')">
      <router-view></router-view>
    </template>
    <template v-else>
      <BasicLayOut />
    </template>
  </div>
</template>

<style></style>
<script lang="ts" setup>
import BasicLayOut from '@/layout/BasicLayOut.vue'
import { useRoute, useRouter } from 'vue-router'
import '@/access/index.ts'
// // 定义两个需要使用的 变量
// const route = useRouter();
const route = useRoute()
// const store = useStore();
// // 路由的跳转时的 判断权限
// router.beforeEach((to, from, next) => {
//   console.log(to);
//   // //判断路由是管理员访问仅管理员可见
//   if (to.meta?.access === "canAdmin") {
//     //判断当前的用户是否有权限
//     if (store.state.user.loginUser?.role !== "admin") {
//       next("/notAuth");
//       return;
//     }
//   }
//   next();
// });
</script>
