<template>
  <div class="container">
    <a-spin :loading="loading" tip="加载中...">
      <a-row :gutter="[16, 16]">
        <!-- 访问量统计卡片 -->
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <a-card class="statistics-card">
            <template #title>
              <div class="card-title">
                <icon-bar-chart />
                <span>网站访问统计</span>
              </div>
            </template>
            <template #extra>
              <div class="card-extra">
                <span class="update-time" v-if="lastUpdateTime">
                  最后更新: {{ lastUpdateTime }}
                </span>
                <a-button type="text" @click="refreshData">
                  <template #icon>
                    <icon-refresh />
                  </template>
                  刷新
                </a-button>
              </div>
            </template>
            <a-row :gutter="16">
              <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <a-statistic
                  title="今日访问量"
                  :value="todayVisits"
                  :precision="0"
                  animation
                >
                  <template #suffix>
                    <icon-user />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <a-statistic
                  title="总访问量"
                  :value="totalVisits"
                  :precision="0"
                  animation
                >
                  <template #suffix>
                    <icon-user-group />
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
            <div class="chart-container">
              <div ref="visitChartRef" class="chart"></div>
            </div>
          </a-card>
        </a-col>

        <!-- 数据分析卡片 -->
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <a-card class="statistics-card">
            <template #title>
              <div class="card-title">
                <icon-desktop />
                <span>设备分布</span>
              </div>
            </template>
            <div class="chart-container">
              <div ref="deviceChartRef" class="chart pie-chart"></div>
            </div>
          </a-card>
        </a-col>

        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <a-card class="statistics-card">
            <template #title>
              <div class="card-title">
                <icon-code-square />
                <span>浏览器分布</span>
              </div>
            </template>
            <div class="chart-container">
              <div ref="browserChartRef" class="chart pie-chart"></div>
            </div>
          </a-card>
        </a-col>

        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <a-card class="statistics-card">
            <template #title>
              <div class="card-title">
                <icon-computer />
                <span>操作系统分布</span>
              </div>
            </template>
            <div class="chart-container">
              <div ref="osChartRef" class="chart pie-chart"></div>
            </div>
          </a-card>
        </a-col>

        <!-- 访问详情表格 -->
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <a-card class="statistics-card">
            <template #title>
              <div class="card-title">
                <icon-calendar />
                <span>每日访问详情</span>
              </div>
            </template>
            <a-table
              :data="statistics"
              :pagination="false"
              :bordered="false"
              stripe
            >
              <template #columns>
                <a-table-column title="日期" data-index="date">
                  <template #cell="{ record }">
                    {{ formatDate(record.date) }}
                  </template>
                </a-table-column>
                <a-table-column title="访问量" data-index="visits">
                  <template #cell="{ record }">
                    <a-progress
                      :percent="calculatePercent(record.visits)"
                      :color="getColorByValue(record.visits)"
                      :show-text="false"
                      style="margin-right: 10px"
                    />
                    <span>{{ record.visits }}</span>
                  </template>
                </a-table-column>
              </template>
            </a-table>
          </a-card>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { VisitRecordControllerService } from '../../../generated/services/VisitRecordControllerService'
import type { BaseResponse_VisitStatisticsVO_ } from '../../../generated/models/BaseResponse_VisitStatisticsVO_'
import { Map_string_object_ } from '../../../generated'
import * as echarts from 'echarts'

const loading = ref(true)
const todayVisits = ref(0)
const totalVisits = ref(0)
const statistics = ref<Array<{ date: string; visits: number }>>([])
const visitChartRef = ref<HTMLElement | null>(null)
const deviceChartRef = ref<HTMLElement | null>(null)
const browserChartRef = ref<HTMLElement | null>(null)
const osChartRef = ref<HTMLElement | null>(null)
const lastUpdateTime = ref('')
let visitChart: echarts.ECharts | null = null
let deviceChart: echarts.ECharts | null = null
let browserChart: echarts.ECharts | null = null
let osChart: echarts.ECharts | null = null
let refreshTimer: number | null = null

// 获取访问统计数据
const fetchStatistics = async () => {
  try {
    loading.value = true
    const response: BaseResponse_VisitStatisticsVO_ =
      await VisitRecordControllerService.getStatisticsUsingGet()

    // 确保数据是数字类型
    todayVisits.value = Number(response.data?.todayVisits) || 0
    totalVisits.value = Number(response.data?.totalVisits) || 0

    console.log('获取到的数据:', {
      today: response.data?.todayVisits,
      total: response.data?.totalVisits,
      todayParsed: todayVisits.value,
      totalParsed: totalVisits.value
    })

    // 处理每日访问数据
    const dailyData = (response.data?.dailyVisits || []).map(
      (item: Map_string_object_) => ({
        date: item.date,
        visits: Number(item.count) || 0
      })
    )

    // 按日期排序
    dailyData.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    )
    statistics.value = dailyData

    // 更新最后更新时间
    updateLastUpdateTime()

    // 渲染图表
    renderVisitChart()

    // 渲染设备分布图表
    renderPieChart(
      deviceChartRef.value,
      deviceChart,
      response.data?.deviceTypeStats || [],
      '设备分布'
    )

    // 渲染浏览器分布图表
    renderPieChart(
      browserChartRef.value,
      browserChart,
      response.data?.browserStats || [],
      '浏览器分布'
    )

    // 渲染操作系统分布图表
    renderPieChart(
      osChartRef.value,
      osChart,
      response.data?.osStats || [],
      '操作系统分布'
    )
  } catch (error) {
    console.error('获取访问统计失败:', error)
  } finally {
    loading.value = false
  }
}

// 更新最后更新时间
const updateLastUpdateTime = () => {
  const now = new Date()
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')
  lastUpdateTime.value = `${hours}:${minutes}:${seconds}`
}

// 设置定时刷新
const setupRefreshTimer = () => {
  // 每60秒刷新一次数据
  refreshTimer = window.setInterval(() => {
    fetchStatistics()
  }, 60000)
}

// 清除定时器
const clearRefreshTimer = () => {
  if (refreshTimer !== null) {
    clearInterval(refreshTimer)
    refreshTimer = null
  }
}

// 手动刷新数据
const refreshData = () => {
  fetchStatistics()
}

// 渲染访问量图表
const renderVisitChart = () => {
  if (!visitChartRef.value || statistics.value.length === 0) return

  // 初始化图表
  if (!visitChart) {
    visitChart = echarts.init(visitChartRef.value)
  }

  // 准备数据
  const dates = statistics.value.map((item) => formatDate(item.date))
  const values = statistics.value.map((item) => item.visits)

  // 设置图表选项
  const option = {
    title: {
      text: '近7天访问量趋势',
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: dates,
      axisLabel: {
        rotate: 30
      }
    },
    yAxis: {
      type: 'value',
      minInterval: 1
    },
    series: [
      {
        name: '访问量',
        type: 'line',
        data: values,
        smooth: true,
        markPoint: {
          data: [
            { type: 'max', name: '最大值' },
            { type: 'min', name: '最小值' }
          ]
        },
        areaStyle: {
          opacity: 0.3
        }
      }
    ]
  }

  // 设置图表
  visitChart.setOption(option)
}

// 渲染饼图
const renderPieChart = (
  chartElement: HTMLElement | null,
  chartInstance: echarts.ECharts | null,
  data: Array<Map_string_object_>,
  title: string
) => {
  if (!chartElement || data.length === 0) return

  // 初始化图表
  let chart = chartInstance
  if (!chart) {
    chart = echarts.init(chartElement)
  }

  // 准备数据
  const pieData = data.map((item) => ({
    name: item.name || '未知',
    value: item.count || 0
  }))

  // 设置图表选项
  const option = {
    title: {
      text: title,
      left: 'center',
      top: 0
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      bottom: 0,
      data: pieData.map((item) => item.name)
    },
    series: [
      {
        name: title,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '14',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: pieData
      }
    ]
  }

  // 设置图表
  chart.setOption(option)

  // 更新图表实例引用
  if (chartElement === deviceChartRef.value) {
    deviceChart = chart
  } else if (chartElement === browserChartRef.value) {
    browserChart = chart
  } else if (chartElement === osChartRef.value) {
    osChart = chart
  }
}

// 格式化日期
const formatDate = (dateStr: string) => {
  const date = new Date(dateStr)
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0'
  )}-${String(date.getDate()).padStart(2, '0')}`
}

// 计算百分比（用于进度条）
const calculatePercent = (visits: number) => {
  const max = Math.max(...statistics.value.map((item) => item.visits))
  return max > 0 ? (visits / max) * 100 : 0
}

// 根据访问量获取颜色
const getColorByValue = (visits: number) => {
  if (visits > 100) return '#ff4d4f'
  if (visits > 50) return '#faad14'
  return '#52c41a'
}

// 响应窗口大小变化
const handleResize = () => {
  visitChart?.resize()
  deviceChart?.resize()
  browserChart?.resize()
  osChart?.resize()
}

onMounted(() => {
  // 初始加载数据
  fetchStatistics()

  // 设置定时刷新
  setupRefreshTimer()

  // 添加窗口大小变化监听
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  // 清除定时器
  clearRefreshTimer()

  // 移除窗口大小变化监听
  window.removeEventListener('resize', handleResize)
})
</script>

<style scoped>
.container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.statistics-card {
  margin-bottom: 20px;
  height: 100%;
}

.card-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.card-extra {
  display: flex;
  align-items: center;
  gap: 12px;
}

.update-time {
  font-size: 0.8em;
  color: #909399;
}

.chart-container {
  margin-top: 20px;
}

.chart {
  width: 100%;
  height: 300px;
}

.pie-chart {
  height: 250px;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .chart {
    height: 250px;
  }

  .pie-chart {
    height: 200px;
  }
}
</style>
