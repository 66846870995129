<template>
  <div id="basicLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <GlobalHeader />
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="foot">
        <div class="footer-links">
          <router-link to="/">首页</router-link>
          <span class="divider">|</span>
          <router-link to="/productcenter">产品中心</router-link>
          <span class="divider">|</span>
          <router-link to="/company">公司介绍</router-link>
          <span class="divider">|</span>
          <router-link to="/materials">资料列表</router-link>
          <span class="divider">|</span>
          <router-link to="/contactus">联系我们</router-link>
        </div>
        <div class="copyright">
          Copyright © 2005-2016 深圳市海泰科技有限公司 粤ICP备09084922号-1
        </div>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<style scoped>
#basicLayout {
}

.header {
  background: #fff;
  color: #fff;
}

.content {
  background: #f5f5f5;
  padding: 20px;
  min-height: calc(100vh - 112px - 80px); /* 减去头部和底部的高度 */
}

.foot {
  width: 100%;
  text-align: center;
  padding: 16px;
  background: #333;
  color: #fff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.footer-links {
  margin-bottom: 8px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 5px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.divider {
  color: #fff;
  margin: 0 5px;
}

.copyright {
  font-size: 12px;
  color: #aaa;
}
</style>

<script>
import GlobalHeader from '@/components/GlobalHeader'

export default {
  components: { GlobalHeader }
}
</script>
