<template>
  <div class="product-list">
    <a-card title="产品信息管理">
      <!-- 搜索区域 -->
      <a-form :model="searchForm" layout="inline" @submit="handleSearch">
        <a-form-item field="name">
          <a-input v-model="searchForm.name" placeholder="产品名称" />
        </a-form-item>
        <a-form-item field="tags">
          <a-input-tag
            v-model="searchForm.tags"
            placeholder="产品标签"
            allow-clear
          />
        </a-form-item>
        <a-form-item field="content">
          <a-input v-model="searchForm.content" placeholder="产品描述" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">搜索</a-button>
          <a-button style="margin-left: 8px" @click="resetSearch"
            >重置</a-button
          >
        </a-form-item>
      </a-form>

      <!-- 操作按钮区域 -->
      <div class="operation-area">
        <a-button type="primary" @click="handleAddProduct">
          <template #icon>
            <icon-plus />
          </template>
          添加产品
        </a-button>
      </div>

      <!-- 表格区域 -->
      <a-table
        :data="products"
        :loading="loading"
        :pagination="pagination"
        @page-change="onPageChange"
      >
        <template #columns>
          <a-table-column title="ID" data-index="id" />
          <a-table-column title="产品名称" data-index="name">
            <template #cell="{ record }">
              <a-link @click="viewProductDetail(record)">
                {{ record.name }}
              </a-link>
            </template>
          </a-table-column>
          <a-table-column title="产品图片" data-index="picture">
            <template #cell="{ record }">
              <a-image
                v-if="record.picture"
                :src="record.picture"
                :preview="false"
                width="80"
                height="80"
                fit="contain"
              />
              <span v-else>无图片</span>
            </template>
          </a-table-column>
          <a-table-column title="标签" data-index="tagList">
            <template #cell="{ record }">
              <a-space wrap>
                <a-tag v-for="tag in record.tagList" :key="tag" color="blue">
                  {{ tag }}
                </a-tag>
              </a-space>
            </template>
          </a-table-column>
          <a-table-column title="创建时间" data-index="createTime">
            <template #cell="{ record }">
              {{ formatDate(record.createTime) }}
            </template>
          </a-table-column>
          <a-table-column title="操作" align="center">
            <template #cell="{ record }">
              <a-space>
                <a-popconfirm
                  content="确定要删除这条产品信息吗？"
                  @ok="handleDelete(record)"
                >
                  <a-button type="primary" status="danger" size="small">
                    删除
                  </a-button>
                </a-popconfirm>
              </a-space>
            </template>
          </a-table-column>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Message } from '@arco-design/web-vue'
import { ProductInfoControllerService } from '../../../generated/services/ProductInfoControllerService'
import type { SimpleProductVO } from '../../../generated/models/SimpleProductVO'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { IconPlus } from '@arco-design/web-vue/es/icon'

const router = useRouter()

// 搜索表单
const searchForm = ref({
  name: '',
  tags: [] as string[],
  content: ''
})

// 表格数据
const loading = ref(false)
const products = ref<SimpleProductVO[]>([])

// 分页配置
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0
})

// 加载数据
const loadData = async () => {
  try {
    loading.value = true
    const res =
      await ProductInfoControllerService.listSimpleProductVoByPageUsingPost({
        ...searchForm.value,
        current: pagination.value.current,
        pageSize: pagination.value.pageSize
      })

    if (res.code === 0 && res.data) {
      products.value = res.data.records || []
      pagination.value.total = res.data.total || 0
    } else {
      Message.error(res.message || '获取数据失败')
    }
  } catch (error) {
    console.error('加载数据失败:', error)
    Message.error('系统错误')
  } finally {
    loading.value = false
  }
}

// 搜索处理
const handleSearch = () => {
  pagination.value.current = 1
  loadData()
}

// 重置搜索
const resetSearch = () => {
  searchForm.value = {
    name: '',
    tags: [],
    content: ''
  }
  handleSearch()
}

// 分页变化
const onPageChange = (page: number) => {
  pagination.value.current = page
  loadData()
}

// 查看产品详情
const viewProductDetail = (record: SimpleProductVO) => {
  router.push(`/product/detail/${record.id}`)
}

// 添加产品
const handleAddProduct = () => {
  router.push('/add/product')
}

// 编辑处理
const handleEdit = (record: SimpleProductVO) => {
  router.push(`/product/edit/${record.id}`)
}

// 删除处理
const handleDelete = async (record: SimpleProductVO) => {
  try {
    loading.value = true
    const res = await ProductInfoControllerService.deleteProductInfoUsingPost({
      id: record.id
    })

    if (res.code === 0) {
      Message.success('删除成功')
      loadData() // 重新加载数据
    } else {
      Message.error(res.message || '删除失败')
    }
  } catch (error) {
    console.error('删除失败:', error)
    Message.error('系统错误')
  } finally {
    loading.value = false
  }
}

// 格式化日期
const formatDate = (date: string | number) => {
  return date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : '-'
}

// 页面加载时获取数据
onMounted(() => {
  loadData()
})
</script>

<style scoped>
.product-list {
  padding: 20px;
}

:deep(.arco-form) {
  margin-bottom: 20px;
}

:deep(.arco-form-item) {
  margin-bottom: 16px;
}

:deep(.arco-table-cell) {
  padding: 8px 16px;
}

.operation-area {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}
</style>
