<template>
  <div class="file-uploader">
    <!-- 已上传文件显示区域 -->
    <div v-if="modelValue" class="file-preview-container">
      <div class="file-preview">
        <icon-file />
        <span class="file-name">{{ getFileName(modelValue) }}</span>
      </div>
      <div class="file-actions">
        <a-button type="text" status="danger" size="mini" @click="handleDelete">
          <icon-delete />
          <span>删除</span>
        </a-button>
      </div>
    </div>

    <!-- 上传按钮区域 -->
    <div v-else class="upload-container">
      <a-upload
        :auto-upload="false"
        :show-file-list="false"
        :accept="accept"
        @change="handleFileSelected"
      >
        <template #upload-button>
          <div class="upload-button">
            <icon-upload />
            <span>点击上传文件</span>
          </div>
        </template>
      </a-upload>
    </div>

    <!-- 上传进度显示 -->
    <div v-if="isUploading" class="upload-progress">
      <a-progress :percent="uploadProgress" />
      <span class="progress-text">上传中 {{ uploadProgress }}%</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits } from 'vue'
import { IconFile, IconDelete, IconUpload } from '@arco-design/web-vue/es/icon'
import type { FileItem } from '@arco-design/web-vue'
import { Message } from '@arco-design/web-vue'
import { FileControllerService } from '../../generated/services/FileControllerService'

// 定义props和emits
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  accept: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:modelValue'])

// 响应式状态
const isUploading = ref(false)
const uploadProgress = ref(0)

// 从URL中提取文件名
const getFileName = (url: string) => {
  if (!url) return ''
  const parts = url.split('/')
  return parts[parts.length - 1]
}

// 处理文件选择
const handleFileSelected = async (_, file: FileItem) => {
  if (!file || !file.file) {
    Message.error('文件选择失败')
    return
  }

  // 开始上传
  await uploadFile(file.file)
}

// 上传文件
const uploadFile = async (file: Blob) => {
  if (isUploading.value) return

  try {
    isUploading.value = true
    uploadProgress.value = 0

    // 模拟上传进度
    const progressInterval = setInterval(() => {
      if (uploadProgress.value < 90) {
        uploadProgress.value += 10
      }
    }, 200)

    // 上传文件
    const response = await FileControllerService.uploadFileUsingPost(
      file,
      'material'
    )

    clearInterval(progressInterval)

    if (response.data && response.data.url) {
      uploadProgress.value = 100
      emit('update:modelValue', response.data.url)
      Message.success('文件上传成功')
    } else {
      throw new Error('上传失败：未获取到文件URL')
    }
  } catch (error) {
    console.error('文件上传失败:', error)
    Message.error('文件上传失败')
    emit('update:modelValue', '')
  } finally {
    setTimeout(() => {
      isUploading.value = false
    }, 500) // 延迟关闭上传状态，让用户看到100%
  }
}

// 处理文件删除
const handleDelete = () => {
  emit('update:modelValue', '')
  Message.success('文件已删除')
}
</script>

<style scoped>
.file-uploader {
  width: 100%;
}

.file-preview-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid var(--color-border-2);
  border-radius: 4px;
  background-color: var(--color-fill-1);
}

.file-preview {
  display: flex;
  align-items: center;
  gap: 8px;
}

.file-name {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-text-1);
}

.file-actions {
  display: flex;
  gap: 8px;
}

.upload-container {
  width: 100%;
}

.upload-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  border: 1px dashed var(--color-border-2);
  border-radius: 4px;
  background-color: var(--color-fill-2);
  cursor: pointer;
  transition: all 0.2s ease;
}

.upload-button:hover {
  border-color: rgb(var(--primary-6));
  background-color: var(--color-fill-3);
  color: rgb(var(--primary-6));
}

.upload-button span {
  margin-top: 8px;
}

.upload-progress {
  margin-top: 8px;
}

.progress-text {
  display: block;
  margin-top: 4px;
  color: var(--color-text-3);
  font-size: 12px;
}
</style>
