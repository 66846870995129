<template>
  <div class="noAccessPage">
    <h1>403 - Forbidden</h1>
    <p>Sorry, you do not have permission to access this page.</p>
  </div>
</template>

<script setup lang="ts"></script>

<style>
.noAccessPage {
  text-align: center;
  margin-top: 20vh;
  font-size: 24px;
  color: red;
}
</style>
