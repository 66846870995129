/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_FileUploadResult_ } from '../models/BaseResponse_FileUploadResult_'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class FileControllerService {
  /**
   * downloadFile
   * @param key key
   * @returns any OK
   * @throws ApiError
   */
  public static downloadFileUsingGet(key: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/file/download',
      query: {
        key: key
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * uploadFile
   * @param file file
   * @param dir dir
   * @returns BaseResponse_FileUploadResult_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static uploadFileUsingPost(
    file: Blob,
    dir: string = 'default'
  ): CancelablePromise<BaseResponse_FileUploadResult_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/file/upload',
      query: {
        dir: dir
      },
      formData: {
        file: file
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
}
