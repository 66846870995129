/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_'
import type { BaseResponse_HomeBannersVo_ } from '../models/BaseResponse_HomeBannersVo_'
import type { BaseResponse_long_ } from '../models/BaseResponse_long_'
import type { DeleteRequest } from '../models/DeleteRequest'
import type { HomeBannersAddRequest } from '../models/HomeBannersAddRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class HomeBannersControllerService {
  /**
   * addHomeBanner
   * @param homeBannersAddRequest homeBannersAddRequest
   * @returns BaseResponse_long_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static addHomeBannerUsingPost(
    homeBannersAddRequest: HomeBannersAddRequest
  ): CancelablePromise<BaseResponse_long_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/banner/add',
      body: homeBannersAddRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * deleteHomeBanner
   * @param deleteRequest deleteRequest
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static deleteHomeBannerUsingPost(
    deleteRequest: DeleteRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/banner/delete',
      body: deleteRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * listHomeBanners
   * @returns BaseResponse_HomeBannersVo_ OK
   * @throws ApiError
   */
  public static listHomeBannersUsingGet(): CancelablePromise<BaseResponse_HomeBannersVo_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/banner/list',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
}
