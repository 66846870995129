/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_'
import type { BaseResponse_List_CategoryTreeVO_ } from '../models/BaseResponse_List_CategoryTreeVO_'
import type { BaseResponse_List_ProductCategory_ } from '../models/BaseResponse_List_ProductCategory_'
import type { BaseResponse_List_ProductInfoVO_ } from '../models/BaseResponse_List_ProductInfoVO_'
import type { BaseResponse_long_ } from '../models/BaseResponse_long_'
import type { BaseResponse_Page_ProductInfoVO_ } from '../models/BaseResponse_Page_ProductInfoVO_'
import type { CategoryAddRequest } from '../models/CategoryAddRequest'
import type { CategoryMoveRequest } from '../models/CategoryMoveRequest'
import type { CategoryProductQueryRequest } from '../models/CategoryProductQueryRequest'
import type { CategoryProductRequest } from '../models/CategoryProductRequest'
import type { CategorySortRequest } from '../models/CategorySortRequest'
import type { CategoryUpdateRequest } from '../models/CategoryUpdateRequest'
import type { DeleteRequest } from '../models/DeleteRequest'
import type { ProductCategoryRequest } from '../models/ProductCategoryRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class ProductCategoryControllerService {
  /**
   * addCategory
   * @param categoryAddRequest categoryAddRequest
   * @returns BaseResponse_long_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static addCategoryUsingPost(
    categoryAddRequest: CategoryAddRequest
  ): CancelablePromise<BaseResponse_long_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/add',
      body: categoryAddRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * getAllChildren
   * @param categoryId categoryId
   * @returns BaseResponse_List_CategoryTreeVO_ OK
   * @throws ApiError
   */
  public static getAllChildrenUsingGet(
    categoryId: number
  ): CancelablePromise<BaseResponse_List_CategoryTreeVO_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/category/children/{categoryId}',
      path: {
        categoryId: categoryId
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * deleteCategory
   * @param deleteRequest deleteRequest
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static deleteCategoryUsingPost(
    deleteRequest: DeleteRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/delete',
      body: deleteRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * moveCategories
   * @param moveRequest moveRequest
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static moveCategoriesUsingPost(
    moveRequest: CategoryMoveRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/move/batch',
      body: moveRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * getCategoryPath
   * @param categoryId categoryId
   * @returns BaseResponse_List_ProductCategory_ OK
   * @throws ApiError
   */
  public static getCategoryPathUsingGet(
    categoryId: number
  ): CancelablePromise<BaseResponse_List_ProductCategory_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/category/path/{categoryId}',
      path: {
        categoryId: categoryId
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * addProductsToCategory
   * @param request request
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static addProductsToCategoryUsingPost(
    request: CategoryProductRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/product/add',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * listCategoryProducts
   * @param request request
   * @returns BaseResponse_Page_ProductInfoVO_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static listCategoryProductsUsingPost(
    request: CategoryProductQueryRequest
  ): CancelablePromise<BaseResponse_Page_ProductInfoVO_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/product/list/page',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * removeProductsFromCategory
   * @param request request
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static removeProductsFromCategoryUsingPost(
    request: CategoryProductRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/product/remove',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * getProductsByCategory
   * @param categoryId categoryId
   * @returns BaseResponse_List_ProductInfoVO_ OK
   * @throws ApiError
   */
  public static getProductsByCategoryUsingGet(
    categoryId: number
  ): CancelablePromise<BaseResponse_List_ProductInfoVO_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/category/products/{categoryId}',
      path: {
        categoryId: categoryId
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * searchProductsByCategories
   * @param categoryIds categoryIds
   * @returns BaseResponse_List_ProductInfoVO_ OK
   * @throws ApiError
   */
  public static searchProductsByCategoriesUsingGet(
    categoryIds: Array<number>
  ): CancelablePromise<BaseResponse_List_ProductInfoVO_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/category/search/products',
      query: {
        categoryIds: categoryIds
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * searchProductsByCategoryNames
   * @param categoryNames categoryNames
   * @returns BaseResponse_List_ProductInfoVO_ OK
   * @throws ApiError
   */
  public static searchProductsByCategoryNamesUsingGet(
    categoryNames: Array<string>
  ): CancelablePromise<BaseResponse_List_ProductInfoVO_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/category/search/products/byName',
      query: {
        categoryNames: categoryNames
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * setProductCategories
   * @param request request
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static setProductCategoriesUsingPost(
    request: ProductCategoryRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/set/batch',
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * updateCategoriesSort
   * @param sortRequest sortRequest
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateCategoriesSortUsingPost(
    sortRequest: CategorySortRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/sort/batch',
      body: sortRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * swapCategorySort
   * @param categoryId categoryId
   * @param direction direction
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static swapCategorySortUsingPost(
    categoryId: number,
    direction: string
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/sort/swap/{categoryId}/{direction}',
      path: {
        categoryId: categoryId,
        direction: direction
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * getCategoryTree
   * @returns BaseResponse_List_CategoryTreeVO_ OK
   * @throws ApiError
   */
  public static getCategoryTreeUsingGet(): CancelablePromise<BaseResponse_List_CategoryTreeVO_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/category/tree',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * updateCategory
   * @param categoryUpdateRequest categoryUpdateRequest
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateCategoryUsingPost(
    categoryUpdateRequest: CategoryUpdateRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/category/update',
      body: categoryUpdateRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
}
