/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_'
import type { BaseResponse_int_ } from '../models/BaseResponse_int_'
import type { BaseResponse_Page_ProductInfo_ } from '../models/BaseResponse_Page_ProductInfo_'
import type { BaseResponse_Page_SimpleProductVO_ } from '../models/BaseResponse_Page_SimpleProductVO_'
import type { BaseResponse_ProductInfoVO_ } from '../models/BaseResponse_ProductInfoVO_'
import type { DeleteRequest } from '../models/DeleteRequest'
import type { ProductInfoAddRequest } from '../models/ProductInfoAddRequest'
import type { ProductInfoQueryRequest } from '../models/ProductInfoQueryRequest'
import type { ProductInfoUpdateRequest } from '../models/ProductInfoUpdateRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class ProductInfoControllerService {
  /**
   * addProductInfo
   * @param productInfoAddRequest productInfoAddRequest
   * @returns BaseResponse_int_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static addProductInfoUsingPost(
    productInfoAddRequest: ProductInfoAddRequest
  ): CancelablePromise<BaseResponse_int_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/productInfo/add',
      body: productInfoAddRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * deleteProductInfo
   * @param deleteRequest deleteRequest
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static deleteProductInfoUsingPost(
    deleteRequest: DeleteRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/productInfo/delete',
      body: deleteRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * getProductInfoVOById
   * @param id id
   * @returns BaseResponse_ProductInfoVO_ OK
   * @throws ApiError
   */
  public static getProductInfoVoByIdUsingGet(
    id?: number
  ): CancelablePromise<BaseResponse_ProductInfoVO_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/productInfo/get/detailById',
      query: {
        id: id
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * listProductInfoByPage
   * @param productInfoQueryRequest productInfoQueryRequest
   * @returns BaseResponse_Page_ProductInfo_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static listProductInfoByPageUsingPost(
    productInfoQueryRequest: ProductInfoQueryRequest
  ): CancelablePromise<BaseResponse_Page_ProductInfo_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/productInfo/list/page',
      body: productInfoQueryRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * listSimpleProductVOByPage
   * @param productInfoQueryRequest productInfoQueryRequest
   * @returns BaseResponse_Page_SimpleProductVO_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static listSimpleProductVoByPageUsingPost(
    productInfoQueryRequest: ProductInfoQueryRequest
  ): CancelablePromise<BaseResponse_Page_SimpleProductVO_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/productInfo/list/page/svo',
      body: productInfoQueryRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * updateProductInfo
   * @param productInfoUpdateRequest productInfoUpdateRequest
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateProductInfoUsingPost(
    productInfoUpdateRequest: ProductInfoUpdateRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/productInfo/update',
      body: productInfoUpdateRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
}
