<template>
  <div class="material-list">
    <a-card title="资料列表">
      <template #extra>
        <a-button
          type="primary"
          @click="router.push('/add/material')"
          v-if="isAdmin"
        >
          添加资料
        </a-button>
      </template>

      <!-- 搜索区域 -->
      <a-form :model="searchForm" layout="inline" @submit="handleSearch">
        <a-form-item field="name">
          <a-input v-model="searchForm.name" placeholder="资料名称" />
        </a-form-item>
        <a-form-item field="fileType">
          <a-select
            v-model="searchForm.fileType"
            placeholder="文件类型"
            allow-clear
          >
            <a-option value="PDF">PDF</a-option>
            <a-option value="WORD">WORD</a-option>
            <a-option value="EXCEL">EXCEL</a-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit"> 搜索 </a-button>
        </a-form-item>
      </a-form>

      <!-- 表格区域 -->
      <a-table
        :data="materials"
        :loading="loading"
        :pagination="pagination"
        @page-change="onPageChange"
      >
        <template #columns>
          <a-table-column title="资料名称" data-index="name" />
          <!-- <a-table-column title="描述" data-index="description" />
          <a-table-column title="文件类型" data-index="fileType" />
          <a-table-column title="下载次数" data-index="downloadCount" /> -->
          <a-table-column title="操作">
            <template #cell="{ record }">
              <a-button
                type="primary"
                size="small"
                :loading="record.downloading"
                @click="handleDownload(record)"
              >
                {{ record.downloading ? '下载中...' : '下载' }}
              </a-button>
            </template>
          </a-table-column>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Message } from '@arco-design/web-vue'
import { ProductMaterialControllerService } from '../../../generated/services/ProductMaterialControllerService'
import { FileControllerService } from '../../../generated/services/FileControllerService'
import { useRouter } from 'vue-router'
import store from '@/store'

const router = useRouter()
const loginUser = store.state.user.loginUser
const isAdmin = ref(loginUser?.userRole === 'admin')

const loading = ref(false)
const materials = ref([])
const searchForm = ref({
  name: '',
  fileType: ''
})

const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0
})

const loadData = async () => {
  try {
    loading.value = true
    const res =
      await ProductMaterialControllerService.listProductInfoByPageUsingPost1({
        ...searchForm.value,
        current: pagination.value.current,
        pageSize: pagination.value.pageSize
      })

    if (res.code === 0 && res.data) {
      materials.value = res.data.records || []
      pagination.value.total = res.data.total || 0
    } else {
      Message.error(res.message || '获取数据失败')
    }
  } catch (error) {
    Message.error('系统错误')
  } finally {
    loading.value = false
  }
}

const handleSearch = () => {
  pagination.value.current = 1
  loadData()
}

const onPageChange = (page: number) => {
  pagination.value.current = page
  loadData()
}

const handleDownload = async (record: any) => {
  try {
    const fileUrl = record.file_url
    if (!fileUrl) {
      Message.error('文件地址不存在')
      return
    }

    record.downloading = true

    const response = await FileControllerService.downloadFileUsingGet(fileUrl)

    const blob = new Blob([response])
    const downloadUrl = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = record.name || '下载文件'
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(downloadUrl)

    Message.success('下载成功')
  } catch (error) {
    console.error('下载失败:', error)
    Message.error('文件下载失败')
  } finally {
    record.downloading = false
  }
}

onMounted(() => {
  loadData()
})
</script>

<style scoped>
.material-list {
  padding: 20px;
}

:deep(.arco-form) {
  margin-bottom: 20px;
}
</style>
