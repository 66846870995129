import { VisitRecordControllerService } from '../../generated/services/VisitRecordControllerService'
import type { VisitRecord } from '../../generated/models/VisitRecord'

class VisitRecordService {
  private sessionId: string

  constructor() {
    // 生成会话ID
    this.sessionId = this.generateSessionId()
  }

  // 生成唯一的会话ID
  private generateSessionId(): string {
    return Date.now().toString(36) + Math.random().toString(36).substring(2)
  }

  // 获取浏览器信息
  private getBrowserInfo(): string {
    const userAgent = navigator.userAgent
    if (userAgent.includes('Firefox')) return 'Firefox'
    if (userAgent.includes('Chrome')) return 'Chrome'
    if (userAgent.includes('Safari')) return 'Safari'
    if (userAgent.includes('Edge')) return 'Edge'
    if (userAgent.includes('MSIE') || userAgent.includes('Trident/'))
      return 'IE'
    return 'Unknown'
  }

  // 获取操作系统信息
  private getOSInfo(): string {
    const userAgent = navigator.userAgent
    if (userAgent.includes('Windows')) return 'Windows'
    if (userAgent.includes('Mac')) return 'MacOS'
    if (userAgent.includes('Linux')) return 'Linux'
    if (userAgent.includes('Android')) return 'Android'
    if (userAgent.includes('iOS')) return 'iOS'
    return 'Unknown'
  }

  // 获取设备类型
  private getDeviceType(): string {
    const userAgent = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return 'Tablet'
    }
    if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent
      )
    ) {
      return 'Mobile'
    }
    return 'Desktop'
  }

  // 记录访问信息
  public async recordVisit(pageUrl: string): Promise<void> {
    try {
      const visitRecord: VisitRecord = {
        session_id: this.sessionId,
        page_url: pageUrl,
        visit_time: new Date().toISOString(),
        user_agent: navigator.userAgent,
        browser: this.getBrowserInfo(),
        os: this.getOSInfo(),
        device_type: this.getDeviceType(),
        referrer: document.referrer,
        stay_duration: 0 // 初始停留时间为0
      }

      await VisitRecordControllerService.recordVisitUsingPost(visitRecord)
    } catch (error) {
      console.error('记录访问信息失败:', error)
    }
  }
}

// 创建单例实例
export const visitRecordService = new VisitRecordService()
