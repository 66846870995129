<template>
  <div class="product-center">
    <div class="product-center-container">
      <!-- 左侧分类树 -->
      <div class="category-tree-container">
        <a-card class="category-tree-card" :bordered="false" title="产品分类">
          <a-spin :loading="treeLoading">
            <category-tree
              ref="categoryTreeRef"
              @node-click="handleCategorySelect"
            />
          </a-spin>
        </a-card>
      </div>

      <!-- 右侧产品展示区 -->
      <div class="product-list-container">
        <a-card :bordered="false">
          <!-- 搜索和筛选区域 -->
          <div class="search-area">
            <a-row :gutter="16" align="middle">
              <a-col :span="8">
                <div class="search-input-wrapper">
                  <icon-search class="search-icon" />
                  <a-input
                    v-model="searchParams.name"
                    placeholder="请输入产品名称搜索"
                    allow-clear
                    @press-enter="handleSearch"
                  />
                </div>
              </a-col>
              <a-col :span="8">
                <a-select
                  v-model="searchParams.sortField"
                  placeholder="排序方式"
                  allow-clear
                  @change="handleSearch"
                >
                  <a-option value="createTime">最新上架</a-option>
                  <a-option value="updateTime">最近更新</a-option>
                  <a-option value="name">名称排序</a-option>
                </a-select>
              </a-col>
              <a-col :span="8">
                <a-space>
                  <a-button type="primary" @click="handleSearch">
                    <template #icon>
                      <icon-search />
                    </template>
                    搜索
                  </a-button>
                  <a-button @click="resetSearch">
                    <template #icon>
                      <icon-refresh />
                    </template>
                    重置
                  </a-button>
                </a-space>
              </a-col>
            </a-row>
          </div>

          <!-- 当前分类和筛选标签 -->
          <div class="filter-tags">
            <a-space>
              <a-tag
                checkable
                :checked="!currentCategory"
                @click="handleClearCategory"
                class="category-tag"
              >
                全部产品
              </a-tag>
              <a-tag
                v-if="currentCategory"
                checkable
                :checked="true"
                @close="handleClearCategory"
                closable
                class="category-tag"
              >
                {{ currentCategory.name }}
              </a-tag>
            </a-space>
          </div>

          <!-- 产品列表 -->
          <div class="product-list">
            <a-spin :loading="loading">
              <a-empty
                v-if="productList.length === 0"
                description="暂无产品数据"
              />
              <a-row v-else :gutter="[16, 16]">
                <a-col
                  v-for="product in productList"
                  :key="product.id"
                  :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="6"
                  :xl="6"
                >
                  <a-card
                    class="product-card"
                    :bodyStyle="{ padding: '12px' }"
                    hoverable
                  >
                    <template #cover>
                      <div
                        class="product-image"
                        @click="handleViewProduct(product)"
                      >
                        <img
                          :src="product.picture || '/default-product.png'"
                          :alt="product.name"
                        />
                      </div>
                    </template>
                    <a-card-meta>
                      <template #title>
                        <div
                          class="product-title"
                          @click="handleViewProduct(product)"
                        >
                          {{ product.name }}
                        </div>
                      </template>
                      <template #description>
                        <div class="product-desc">
                          {{ product.content || '暂无描述' }}
                        </div>
                        <div
                          class="product-tags"
                          v-if="product.tagList && product.tagList.length"
                        >
                          <a-tag
                            v-for="(tag, index) in product.tagList"
                            :key="index"
                            color="blue"
                            size="small"
                          >
                            {{ tag }}
                          </a-tag>
                        </div>
                        <div class="product-actions">
                          <a-button
                            v-if="product.taobao_link"
                            type="text"
                            size="mini"
                            @click="openTaobaoLink(product.taobao_link)"
                          >
                            <template #icon>
                              <icon-apps />
                            </template>
                            购买链接
                          </a-button>
                        </div>
                      </template>
                    </a-card-meta>
                  </a-card>
                </a-col>
              </a-row>
            </a-spin>
          </div>

          <!-- 分页 -->
          <div class="pagination">
            <a-pagination
              v-model:current="searchParams.current"
              v-model:pageSize="searchParams.pageSize"
              :total="total"
              show-total
              show-sizer
              show-jumper
              @change="handlePageChange"
              @page-size-change="handlePageSizeChange"
            />
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, computed } from 'vue'
import { Message } from '@arco-design/web-vue'
import { IconSearch, IconRefresh, IconApps } from '@arco-design/web-vue/es/icon'
import CategoryTree from './components/DisplayCategoryTree.vue'
import { ProductInfoControllerService } from '../../../generated/services/ProductInfoControllerService'
import { ProductCategoryControllerService } from '../../../generated/services/ProductCategoryControllerService'
import type { ProductInfoVO } from '../../../generated/models/ProductInfoVO'
import type { CategoryTreeVO } from '../../../generated/models/CategoryTreeVO'
import type { CategoryProductQueryRequest } from '../../../generated/models/CategoryProductQueryRequest'

// 搜索参数
const searchParams = reactive({
  name: '',
  current: 1,
  pageSize: 12,
  categoryId: undefined as number | undefined,
  sortField: undefined as string | undefined,
  sortOrder: 'descend' as 'ascend' | 'descend',
  includeChildren: true
})

// 数据
const productList = ref<ProductInfoVO[]>([])
const total = ref(0)
const loading = ref(false)
const treeLoading = ref(false)
const currentCategory = ref<CategoryTreeVO | null>(null)

// 加载产品列表
const loadProductList = async () => {
  loading.value = true
  try {
    if (searchParams.categoryId) {
      // 使用分类接口获取产品
      const response =
        await ProductCategoryControllerService.listCategoryProductsUsingPost({
          categoryId: searchParams.categoryId,
          current: searchParams.current,
          pageSize: searchParams.pageSize,
          productName: searchParams.name,
          includeChildren: searchParams.includeChildren,
          sortField: searchParams.sortField,
          sortOrder: searchParams.sortOrder
        })
      if (response.data) {
        productList.value = response.data.records || []
        total.value = response.data.total || 0
      }
    } else {
      // 使用产品接口获取所有产品
      const response =
        await ProductInfoControllerService.listSimpleProductVoByPageUsingPost({
          name: searchParams.name,
          current: searchParams.current,
          pageSize: searchParams.pageSize,
          sortField: searchParams.sortField,
          sortOrder: searchParams.sortOrder
        })
      if (response.data) {
        productList.value = response.data.records || []
        total.value = response.data.total || 0
      }
    }
  } catch (error) {
    console.error('加载产品列表失败:', error)
    Message.error('加载产品列表失败')
  } finally {
    loading.value = false
  }
}

// 处理分类选择
const handleCategorySelect = (node: CategoryTreeVO) => {
  searchParams.categoryId = node.id
  searchParams.current = 1
  currentCategory.value = node
  loadProductList()
}

// 清除分类筛选
const handleClearCategory = () => {
  searchParams.categoryId = undefined
  searchParams.current = 1
  currentCategory.value = null
  loadProductList()
}

// 处理搜索
const handleSearch = () => {
  searchParams.current = 1
  loadProductList()
}

// 重置搜索
const resetSearch = () => {
  searchParams.name = ''
  searchParams.categoryId = undefined
  searchParams.sortField = undefined
  searchParams.current = 1
  currentCategory.value = null
  loadProductList()
}

// 处理分页变化
const handlePageChange = (page: number) => {
  searchParams.current = page
  loadProductList()
}

// 处理每页条数变化
const handlePageSizeChange = (pageSize: number) => {
  searchParams.pageSize = pageSize
  searchParams.current = 1
  loadProductList()
}

// 查看产品详情
const handleViewProduct = (product: ProductInfoVO) => {
  if (product.id) {
    window.open(`/product/detail/${product.id}`, '_blank')
  }
}

// 打开淘宝链接
const openTaobaoLink = (url: string) => {
  if (url) {
    window.open(url, '_blank')
  }
}

// 初始化
onMounted(() => {
  treeLoading.value = true
  loadProductList().finally(() => {
    treeLoading.value = false
  })
})
</script>

<style scoped>
.product-center {
  padding: 20px;
  height: 100%;
  background-color: var(--color-fill-2);
}

.product-center-container {
  display: flex;
  gap: 20px;
  height: 100%;
}

.category-tree-container {
  width: 280px;
  flex-shrink: 0;
}

.category-tree-card {
  height: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.product-list-container {
  flex: 1;
}

.search-area {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  color: var(--color-text-3);
}

.search-input-wrapper :deep(.arco-input) {
  padding-left: 32px;
}

.filter-tags {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.category-tag {
  cursor: pointer;
  font-size: 14px;
  padding: 4px 12px;
}

.product-list {
  margin: 20px 0;
  min-height: 200px;
}

.product-card {
  height: 100%;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  border: none;
}

.product-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.product-image {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product-image:hover img {
  transform: scale(1.05);
}

.product-title {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text-1);
  margin-bottom: 8px;
}

.product-title:hover {
  color: rgb(var(--primary-6));
}

.product-desc {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--color-text-3);
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 8px;
}

.product-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.product-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.pagination {
  margin-top: 20px;
  text-align: right;
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

:deep(.arco-card-header) {
  border-bottom: 1px solid var(--color-border-2);
}

:deep(.arco-input-search) {
  width: 100%;
}

:deep(.arco-select) {
  width: 100%;
}

:deep(.arco-tag) {
  margin: 0;
}

:deep(.arco-breadcrumb-item) {
  cursor: pointer;
}

:deep(.arco-breadcrumb-item:hover) {
  color: rgb(var(--primary-6));
}

/* 响应式布局 */
@media (max-width: 768px) {
  .product-center-container {
    flex-direction: column;
  }

  .category-tree-container {
    width: 100%;
    margin-bottom: 16px;
  }

  .search-area .arco-row {
    row-gap: 16px;
  }

  .search-area .arco-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
