<template>
  <div class="home-container">
    <!-- 轮播图部分 - 顶置 -->
    <div class="carousel-container">
      <a-carousel
        :auto-play="true"
        animation-name="fade"
        show-arrow="hover"
        indicator-position="outer"
        class="main-carousel"
      >
        <a-carousel-item v-for="(image, index) in images" :key="index">
          <div class="carousel-item">
            <img :src="image" class="carousel-image" alt="轮播图" />
          </div>
        </a-carousel-item>
      </a-carousel>
    </div>

    <!-- 搜索框 -->
    <div class="search-container">
      <a-input-search
        v-model="searchText"
        placeholder="搜索商品..."
        search-button
        @search="handleSearch"
        :style="{ width: '50%', margin: '20px auto' }"
      />
    </div>

    <!-- 卡片形式商品展示 -->
    <div class="product-container">
      <h2 class="section-title">产品展示</h2>

      <!-- 加载状态 -->
      <div v-if="loading" class="loading-container">
        <a-spin :size="30" tip="加载中..."></a-spin>
      </div>

      <!-- 空状态 -->
      <div v-else-if="products.length === 0" class="empty-container">
        <a-empty description="暂无产品" />
      </div>

      <!-- 产品列表 -->
      <div v-else class="product-list">
        <div
          v-for="product in products"
          :key="product.id"
          class="product-item"
          @click="handleProductClick(product)"
        >
          <a-card class="product-card" :bordered="false" :hoverable="true">
            <template #cover>
              <div class="image-container">
                <img
                  :src="product.picture || defaultImage"
                  class="product-image"
                  alt="产品图片"
                  @error="handleImageError"
                />
              </div>
            </template>
            <template #title>{{ product.name }}</template>
            <template #extra>
              <a-button type="text" @click.stop="handleProductClick(product)">
                查看详情
              </a-button>
            </template>
            <div class="product-tags">
              <a-tag v-for="tag in product.tagList" :key="tag" color="blue">
                {{ tag }}
              </a-tag>
            </div>
          </a-card>
        </div>
      </div>

      <!-- 分页组件 -->
      <div class="pagination-container" v-if="total > 0">
        <a-pagination
          v-model:current="currentPage"
          :total="total"
          :page-size="pageSize"
          @change="handlePageChange"
          show-total
          show-jumper
          :hide-on-single-page="false"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import {
  HomeBannersVo,
  ProductInfoControllerService,
  SimpleProductVO,
  HomeBannersControllerService
} from '../../generated'
import { Message } from '@arco-design/web-vue'

const router = useRouter()
const searchText = ref('')
const products = ref<SimpleProductVO[]>([])
const banners = ref<HomeBannersVo>()
const images = computed(() => banners.value?.image_url || [])
const currentPage = ref(1)
const pageSize = ref(8) // 每页显示8个产品，更符合常见的网格布局
const total = ref(0)
const loading = ref(false)
const defaultImage =
  'https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp'

// 获取商品列表
const fetchProducts = async (searchKey = '') => {
  loading.value = true
  try {
    const response =
      await ProductInfoControllerService.listSimpleProductVoByPageUsingPost({
        current: currentPage.value,
        pageSize: pageSize.value,
        name: searchKey
      })
    if (response.data) {
      products.value = response.data.records || []
      total.value = response.data.total || 0

      if (products.value.length === 0 && searchKey) {
        Message.info('没有找到相关产品')
      }
    }
  } catch (error) {
    console.error('获取商品列表失败:', error)
    Message.error('获取产品列表失败，请稍后重试')
    products.value = []
    total.value = 0
  } finally {
    loading.value = false
  }
}

// 获取轮播图数据
const fetchBanners = async () => {
  try {
    const response =
      await HomeBannersControllerService.listHomeBannersUsingGet()
    if (response.data) {
      banners.value = response.data
    }
  } catch (error) {
    console.error('获取轮播图失败:', error)
    Message.error('获取轮播图失败，请稍后重试')
  }
}

// 处理页码变化
const handlePageChange = (page: number) => {
  currentPage.value = page
  fetchProducts(searchText.value)
  // 滚动到产品区域顶部
  const productContainer = document.querySelector('.product-container')
  if (productContainer) {
    productContainer.scrollIntoView({ behavior: 'smooth' })
  }
}

// 搜索处理
const handleSearch = (value: string) => {
  currentPage.value = 1 // 搜索时重置页码
  searchText.value = value
  fetchProducts(value)
}

// 处理图片加载错误
const handleImageError = (e: Event) => {
  const target = e.target as HTMLImageElement
  target.src = defaultImage
}

//商品点击处理
const handleProductClick = (product: SimpleProductVO) => {
  router.push({
    name: 'ProductDetail',
    params: { id: product.id }
  })
}

// 跳转到商品列表
const goToProductList = () => {
  router.push('/product/list')
}

onMounted(() => {
  fetchProducts()
  fetchBanners()
})
</script>

<style scoped>
.home-container {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.carousel-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.main-carousel {
  width: 100%;
  height: 400px;
}

.carousel-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  padding: 0 20px;
}

.section-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: #1890ff;
}

.product-container {
  margin: 40px auto;
  padding: 0 40px;
  max-width: 1200px;
}

.loading-container,
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(4, 280px);
  gap: 20px;
  justify-content: start;
  width: 100%;
}

.product-item {
  width: 100%;
  margin-bottom: 0;
}

.product-card {
  height: 100%;
  transition: all 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.image-container {
  height: 180px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.product-card:hover .product-image {
  transform: scale(1.05);
}

.product-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
}

.more-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-card {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-content {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-text-3);
  font-size: 16px;
}

.more-content :deep(.arco-icon) {
  font-size: 32px;
  margin-bottom: 12px;
}

.pagination-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

@media (max-width: 1280px) {
  .product-list {
    grid-template-columns: repeat(3, 280px);
  }
}

@media (max-width: 992px) {
  .product-list {
    grid-template-columns: repeat(2, 280px);
  }
}

@media (max-width: 768px) {
  .main-carousel {
    height: 200px;
  }

  .product-container {
    padding: 0 20px;
  }

  .product-list {
    grid-template-columns: repeat(1, 280px);
  }

  .product-item {
    width: 100%;
    max-width: 320px;
  }

  .pagination-container {
    margin-top: 20px;
  }
}
</style>
