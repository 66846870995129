<template>
  <div class="header">Login</div>
  <a-form
    style="max-width: 480px; margin: 0 auto"
    label-align="left"
    auto-label-width
    :model="form"
    @submit="handleSubmit"
  >
    <a-form-item
      field="userAccount"
      tooltip="Please enter username"
      label="Username"
    >
      <a-input v-model="form.userAccount" placeholder="请输入用户名" />
    </a-form-item>
    <a-form-item
      field="userPassword"
      label="userPassword"
      tooltip="Please enter password"
    >
      <a-input v-model="form.userPassword" placeholder="请输入密码" />
    </a-form-item>
    <a-form-item>
      <a-button html-type="submit" class="btn" :loading="loading"
        >Submit</a-button
      >
    </a-form-item>
    <div class="msg">
      Don't have account?
      <router-link to="/user/register">Sign up</router-link>
    </div>
  </a-form>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { UserControllerService, UserLoginRequest } from '../../../generated'
import message from '@arco-design/web-vue/es/message'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

/**
 * 表单的信息
 */
const form = reactive({
  userAccount: '',
  userPassword: ''
} as UserLoginRequest)

const router = useRouter()
const store = useStore()
const loading = ref(false)

const handleSubmit = async () => {
  if (!form.userAccount || !form.userPassword) {
    message.error('用户名和密码不能为空')
    return
  }

  loading.value = true
  try {
    console.log('开始登录，提交数据:', JSON.stringify(form))
    const res = await UserControllerService.userLoginUsingPost(form)
    console.log('登录响应:', JSON.stringify(res))

    //登录成功，跳转到主页
    if (res.code === 0) {
      message.success('登录成功')
      // 获取登录用户信息
      const userInfo = await store.dispatch('user/getLoginUser')
      console.log('获取到的用户信息:', JSON.stringify(userInfo))

      if (userInfo) {
        await router.push({
          path: '/',
          replace: true
        })
      } else {
        message.error('获取用户信息失败，请重新登录')
      }
    } else {
      message.error('登录失败，' + res.message)
    }
  } catch (error) {
    console.error('登录过程出错:', error)
    message.error('登录出错，请稍后重试')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
.header {
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  line-height: 200px;
}

.btn {
  text-align: center;
  padding: 10px;
  width: 100%;
  margin-top: 40px;
  background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
  color: #fff;
}

.msg {
  text-align: center;
  line-height: 88px;
}
</style>
