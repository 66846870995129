<template>
  <div class="globalHeader">
    <!-- 左侧Logo和标题区域 -->
    <div class="header-left">
      <div class="title-bar">
        <img
          class="logo"
          :src="companyInfo?.logo_url || require('../assets/logo.png')"
          @click="goToHome"
        />
        <div class="title" @click="goToHome">
          {{ companyInfo?.company_name || '深圳实久科技有限公司' }}
        </div>
      </div>
    </div>

    <!-- 右侧导航菜单区域 -->
    <div class="header-right">
      <!-- 移动端菜单按钮 -->
      <div class="mobile-menu-button" @click="toggleMobileMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>

      <!-- 导航菜单 -->
      <div class="menu-area" :class="{ 'mobile-menu-open': mobileMenuOpen }">
        <ul class="nav-menu">
          <li
            v-for="item in visibleMainRoutes"
            :key="item.path"
            :class="{ active: selectedKeys.includes(item.path) }"
            @click="doMenuClick(item.path)"
          >
            {{ item.name }}
          </li>

          <!-- 更多菜单下拉 -->
          <li class="more-menu" v-if="moreRoutes.length > 0">
            <a-dropdown trigger="hover" position="bottom">
              <div
                class="more-menu-trigger"
                :class="{ active: isMoreMenuActive }"
              >
                更多
                <icon-down />
              </div>
              <template #content>
                <a-doption
                  v-for="item in moreRoutes"
                  :key="item.path"
                  :class="{
                    'active-dropdown-item': selectedKeys.includes(item.path)
                  }"
                  @click="doMenuClick(item.path)"
                >
                  {{ item.name }}
                </a-doption>
              </template>
            </a-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// 脚本部分，您可以在这里添加组件逻辑
import { routes } from '@/router/routes'
import { useRouter } from 'vue-router'
import { computed, ref, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import checkAccess from '@/access/checkAccess'
import { IconDown } from '@arco-design/web-vue/es/icon'
import { CompanyInfoControllerService } from '../../generated'
import type { CompanyInfoVo } from '../../generated'

// 公司信息
const companyInfo = ref<CompanyInfoVo | null>(null)

// 获取公司信息
const fetchCompanyInfo = async () => {
  try {
    const response = await CompanyInfoControllerService.getCompanyInfoUsingGet()
    if (response.data) {
      companyInfo.value = response.data
    }
  } catch (error) {
    console.error('获取公司信息失败:', error)
  }
}

// 移动端菜单状态
const mobileMenuOpen = ref(false)

// 切换移动端菜单
const toggleMobileMenu = () => {
  mobileMenuOpen.value = !mobileMenuOpen.value
}

const router = useRouter()
// 默认选择的页面
const selectedKeys = ref(['/'])
// 这是路由的一个钩子函数
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path]
  // 关闭移动端菜单
  mobileMenuOpen.value = false
})
//这是路由的一个点击事件的函数
const doMenuClick = (key: string) => {
  router.push({
    path: key
  })
}

// 添加跳转到主页的方法
const goToHome = () => {
  router.push('/')
}

//这是引入的状态的变量
const store = useStore()
const loginUser = store.state.user?.loginUser

// 所有可见路由
const allVisibleRoutes = computed(() => {
  return routes.filter((item) => {
    if (item.meta?.hideInMenu) {
      return false
    }
    if (
      !checkAccess(store.state.user.loginUser, item?.meta?.access as string)
    ) {
      return false
    }
    return true
  })
})

// 主菜单显示的路由数量
const mainMenuCount = ref(5)

// 窗口大小变化时调整主菜单显示数量
const updateMainMenuCount = () => {
  const width = window.innerWidth
  if (width > 1200) {
    mainMenuCount.value = 7
  } else if (width > 992) {
    mainMenuCount.value = 5
  } else if (width > 768) {
    mainMenuCount.value = 3
  } else {
    mainMenuCount.value = allVisibleRoutes.value.length // 移动端显示所有菜单
  }
}

// 主菜单显示的路由
const visibleMainRoutes = computed(() => {
  return allVisibleRoutes.value.slice(0, mainMenuCount.value)
})

// 更多菜单显示的路由
const moreRoutes = computed(() => {
  return allVisibleRoutes.value.slice(mainMenuCount.value)
})

// 判断更多菜单是否激活
const isMoreMenuActive = computed(() => {
  return moreRoutes.value.some((item) => selectedKeys.value.includes(item.path))
})

// 监听窗口大小变化
onMounted(() => {
  updateMainMenuCount()
  window.addEventListener('resize', updateMainMenuCount)
  // 获取公司信息
  fetchCompanyInfo()
})

onUnmounted(() => {
  window.removeEventListener('resize', updateMainMenuCount)
})

// 监听路由变化，更新选中状态
watch(
  () => router.currentRoute.value.path,
  (newPath) => {
    selectedKeys.value = [newPath]
  }
)
</script>

<style scoped>
.globalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: 80px;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
}

.header-left {
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 33.33%; /* 占据三分之一的宽度 */
}

.header-right {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* 菜单靠右对齐 */
  max-width: 66.67%; /* 占据三分之二的宽度 */
}

.title-bar {
  display: flex;
  align-items: center;
  width: 100%;
}

.logo {
  height: 50px;
  width: auto;
  object-fit: contain;
  cursor: pointer; /* 添加鼠标指针样式 */
}

.title {
  color: #333;
  margin-left: 16px;
  font-weight: bold;
  font-size: 24px;
  white-space: nowrap; /* 防止文字换行 */
  cursor: pointer; /* 添加鼠标指针样式 */
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-area {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end; /* 菜单项靠右对齐 */
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end; /* 菜单项靠右对齐 */
  width: 100%;
  max-width: 800px; /* 限制最大宽度，避免菜单项过于分散 */
}

.nav-menu li {
  padding: 0 16px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  position: relative;
  height: 80px;
  line-height: 80px;
  transition: all 0.3s;
  text-align: center;
  white-space: nowrap; /* 防止文字换行 */
}

.nav-menu li:hover {
  color: #1890ff;
}

.nav-menu li.active {
  color: #1890ff;
}

.nav-menu li.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  height: 2px;
  background-color: #1890ff;
}

/* 更多菜单样式 */
.more-menu {
  position: relative;
}

.more-menu-trigger {
  display: flex;
  align-items: center;
  gap: 4px;
}

.more-menu-trigger.active {
  color: #1890ff;
}

.more-menu-trigger.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  height: 2px;
  background-color: #1890ff;
}

:deep(.arco-dropdown-option) {
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s;
}

:deep(.arco-dropdown-option:hover) {
  background-color: #f5f5f5;
  color: #1890ff;
}

:deep(.active-dropdown-item) {
  color: #1890ff;
  background-color: #e6f7ff;
}

/* 移动端菜单按钮 */
.mobile-menu-button {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  z-index: 1000;
}

.mobile-menu-button .bar {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s;
}

/* 响应式布局 */
@media screen and (max-width: 1200px) {
  .nav-menu li {
    padding: 0 12px;
  }
}

@media screen and (max-width: 1024px) {
  .globalHeader {
    padding: 0 20px;
  }

  .header-left {
    max-width: 50%;
  }

  .title {
    font-size: 20px;
  }

  .nav-menu li {
    padding: 0 8px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .mobile-menu-button {
    display: flex;
  }

  .header-left {
    max-width: 70%;
  }

  .menu-area {
    position: fixed;
    top: 80px;
    right: -100%;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: white;
    transition: all 0.3s;
    z-index: 999;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* 添加垂直滚动 */
    -webkit-overflow-scrolling: touch; /* 增加 iOS 滚动流畅度 */
  }

  .menu-area.mobile-menu-open {
    right: 0;
  }

  .nav-menu {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 10px 0;
  }

  .nav-menu li {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f0f0f0;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  .nav-menu li:last-child {
    border-bottom: none;
  }

  .nav-menu li.active::after {
    display: none;
  }

  .nav-menu li.active {
    background-color: #e6f7ff;
    color: #1890ff;
  }

  .more-menu {
    display: none; /* 移动端不显示更多菜单 */
  }
}

@media screen and (max-width: 480px) {
  .logo {
    height: 40px;
  }

  .title {
    font-size: 16px;
  }

  .globalHeader {
    padding: 0 10px;
  }
}
</style>
