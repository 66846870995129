<template>
  <div id="ProductDetailView">
    <div class="product-detail-container">
      <!-- 上半部分：产品展示和基本信息 -->
      <div class="product-top-section">
        <!-- 左侧：轮播图区域 -->
        <div class="media-column">
          <a-carousel
            class="main-carousel"
            :auto-play="true"
            animation-name="fade"
            show-arrow="hover"
            indicator-position="outer"
          >
            <a-carousel-item
              v-for="(image, index) in carouselImages"
              :key="index"
            >
              <div class="image-wrapper">
                <img :src="image" class="carousel-image" alt="产品图片" />
              </div>
            </a-carousel-item>
          </a-carousel>
        </div>

        <!-- 右侧：产品信息区域 -->
        <div class="info-column">
          <div class="info-content">
            <h1 class="product-title">{{ product?.name }}</h1>

            <!-- 产品标签 -->
            <div class="tag-section">
              <a-tag
                v-for="tag in product?.tagList"
                :key="tag"
                color="arcoblue"
                class="product-tag"
              >
                {{ tag }}
              </a-tag>
            </div>

            <!-- 添加分享和二维码区域 -->
            <div class="action-section">
              <div class="qrcode-section">
                <a-popover position="bottom" trigger="hover">
                  <div class="qrcode-trigger">
                    <icon-qrcode class="action-icon" />
                    <span>微信咨询</span>
                  </div>
                  <template #content>
                    <div class="qrcode-container">
                      <img
                        v-if="companyInfo?.wechat_qrcode"
                        :src="companyInfo.wechat_qrcode"
                        class="wechat-qrcode"
                        alt="微信二维码"
                      />
                      <div class="qrcode-tip">扫码添加客服微信</div>
                    </div>
                  </template>
                </a-popover>
              </div>

              <div class="share-section">
                <a-dropdown @select="onShare">
                  <div class="share-trigger">
                    <icon-share-alt class="action-icon" />
                    <span>分享</span>
                  </div>
                  <template #content>
                    <a-doption value="copy"> <icon-copy /> 复制链接 </a-doption>
                    <a-doption value="weixin">
                      <icon-wechat /> 微信分享
                    </a-doption>
                    <a-doption value="weibo">
                      <icon-weibo /> 微博分享
                    </a-doption>
                  </template>
                </a-dropdown>
              </div>
            </div>

            <div class="purchase-wrapper">
              <a-button
                type="primary"
                size="large"
                class="purchase-btn"
                @click="handlePurchase"
                :disabled="!product?.taobao_link"
              >
                <template #icon>
                  <icon-shopping-cart />
                </template>
                {{ product?.taobao_link ? '立即购买' : '暂未上架' }}
              </a-button>
            </div>
          </div>
        </div>
      </div>

      <!-- 分割线 -->
      <a-divider>商品详细信息</a-divider>

      <!-- 下半部分：商品详情 -->
      <div class="product-bottom-section" v-if="product?.content">
        <div class="description-container">
          <MdViewer :value="product.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { Message } from '@arco-design/web-vue'
import {
  ProductInfoControllerService,
  CompanyInfoControllerService
} from '../../../generated'
import type { ProductInfoVO } from '../../../generated'
import type { CompanyInfoVo } from '../../../generated'
import MdViewer from '@/components/MdViewer.vue'
import {
  IconQrcode,
  IconShareAlt,
  IconCopy,
  IconWechat,
  IconWeibo
} from '@arco-design/web-vue/es/icon'

const route = useRoute()
const loading = ref(false)
const product = ref<ProductInfoVO | null>(null)
const companyInfo = ref<CompanyInfoVo | null>(null)

// 添加计算属性处理图片数组
const carouselImages = computed(() => {
  // 如果有封面图，将其作为第一张图片
  const images = []
  if (product.value?.picture) {
    images.push(product.value.picture)
  }
  // 添加其他详细图片
  if (product.value?.imageUrls?.length) {
    images.push(...product.value.imageUrls)
  }
  return images
})

// 获取公司信息
const fetchCompanyInfo = async () => {
  try {
    const response = await CompanyInfoControllerService.getCompanyInfoUsingGet()
    if (response.data) {
      companyInfo.value = response.data
    }
  } catch (error) {
    console.error('获取公司信息失败:', error)
  }
}

// 处理分享功能
const onShare = (type: string) => {
  if (!product.value) return

  const shareUrl = window.location.href
  const title = product.value.name || '产品详情'

  switch (type) {
    case 'copy': {
      navigator.clipboard.writeText(shareUrl).then(() => {
        Message.success('链接已复制到剪贴板')
      })
      break
    }
    case 'weixin': {
      Message.info('请使用微信扫描二维码分享')
      break
    }
    case 'weibo': {
      const weiboUrl = `http://service.weibo.com/share/share.php?url=${encodeURIComponent(
        shareUrl
      )}&title=${encodeURIComponent(title)}`
      window.open(weiboUrl, '_blank')
      break
    }
  }
}

//  处理购买
const handlePurchase = () => {
  if (product.value?.taobao_link) {
    window.open(product.value.taobao_link, '_blank')
  } else {
    Message.warning('商品暂未上架')
  }
}

// 获取商品详情
const fetchProductDetail = async () => {
  const productId = route.params.id as string
  if (!productId) return

  loading.value = true
  try {
    const response =
      await ProductInfoControllerService.getProductInfoVoByIdUsingGet(
        Number(productId)
      )
    if (response.data) {
      product.value = response.data
    }
  } catch (error) {
    console.error('获取商品详情失败:', error)
    Message.error('获取商品详情失败')
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchProductDetail()
  fetchCompanyInfo()
})
</script>

<style scoped>
.product-detail-container {
  max-width: 1200px;
  margin: 24px auto;
  padding: 0 24px;
}

.product-top-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 48px;
  min-height: 500px;
}

.media-column {
  background: var(--color-bg-2);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  height: 500px; /* 固定高度 */
}

.main-carousel {
  height: 100%;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.carousel-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}

.info-column {
  padding: 32px;
  background: var(--color-bg-2);
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.product-title {
  font-size: 28px;
  font-weight: 600;
  color: var(--color-text-1);
  margin-bottom: 24px;
}

.tag-section {
  margin-bottom: 32px;
}

.product-tag {
  margin: 0 8px 8px 0;
  padding: 6px 16px;
  border-radius: 16px;
}

.product-info {
  margin-bottom: 32px;
}

.info-item {
  margin-bottom: 24px;
}

.info-item h4 {
  font-size: 16px;
  color: var(--color-text-2);
  margin-bottom: 8px;
}

.info-item p {
  color: var(--color-text-1);
  line-height: 1.6;
}

.purchase-wrapper {
  margin-top: auto;
  padding-top: 24px;
  border-top: 1px solid var(--color-border-2);
}

.purchase-btn {
  width: 100%;
  height: 48px;
  font-size: 16px;
  border-radius: 24px;
}

.product-bottom-section {
  background: var(--color-bg-2);
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .product-top-section {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .info-column {
    padding: 24px;
  }

  .product-bottom-section {
    padding: 24px;
  }
}

.action-section {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  padding: 16px 0;
  border-top: 1px solid var(--color-border-2);
  border-bottom: 1px solid var(--color-border-2);
}

.qrcode-section,
.share-section {
  cursor: pointer;
}

.qrcode-trigger,
.share-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-text-2);
  transition: color 0.3s;
}

.qrcode-trigger:hover,
.share-trigger:hover {
  color: rgb(var(--primary-6));
}

.action-icon {
  font-size: 20px;
}

.qrcode-container {
  padding: 16px;
  text-align: center;
}

.wechat-qrcode {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.qrcode-tip {
  margin-top: 8px;
  color: var(--color-text-2);
  font-size: 14px;
}
</style>
