<template>
  <div class="header">Sign Up</div>
  <a-form
    style="max-width: 480px; margin: 0 auto"
    label-align="left"
    auto-label-width
    :model="form"
    @submit="handleSubmit"
  >
    <a-form-item
      field="userAccount"
      tooltip="Please enter username"
      label="Username"
    >
      <a-input v-model="form.userAccount" placeholder="请输入用户名" />
    </a-form-item>
    <a-form-item
      field="userPassword"
      label="userPassword"
      tooltip="Please enter password"
    >
      <a-input v-model="form.userPassword" placeholder="请输入密码" />
    </a-form-item>
    <a-form-item
      field="checkUserPassword"
      label="userPassword"
      tooltip="Please enter password again"
    >
      <a-input v-model="form.checkPassword" placeholder="请再次输入密码" />
    </a-form-item>
    <a-form-item>
      <a-button html-type="submit" class="btn">Submit</a-button>
    </a-form-item>
  </a-form>
  {{ form }}
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import {
  UserControllerService,
  UserLoginRequest,
  UserRegisterRequest
} from '../../../generated'
import message from '@arco-design/web-vue/es/message'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

/**
 * 表单的信息
 */
const form = reactive({
  userAccount: '',
  userPassword: '',
  checkPassword: ''
} as UserRegisterRequest)

const router = useRouter()

const handleSubmit = async () => {
  const res = await UserControllerService.userRegisterUsingPost(form)
  //注册成功，跳转到主页
  if (res.code === 0) {
    await router.push({
      path: '/user/login',
      replace: true
    })
  } else {
    message.error('注册失败，' + res.message)
  }
}
</script>

<style scoped>
.header {
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  line-height: 200px;
}

.btn {
  text-align: center;
  padding: 10px;
  width: 100%;
  margin-top: 40px;
  background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
  color: #fff;
}

.msg {
  text-align: center;
  line-height: 88px;
}
</style>
