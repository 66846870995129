<template>
  <div class="banner-manage-container">
    <a-card title="轮播图管理">
      <template #extra>
        <a-button type="primary" @click="showAddModal">
          <template #icon>
            <icon-plus />
          </template>
          添加轮播图
        </a-button>
      </template>

      <!-- 轮播图列表 -->
      <a-spin :loading="loading">
        <div v-if="banners.length > 0" class="banner-list">
          <a-row :gutter="[16, 16]">
            <a-col
              v-for="(banner, index) in banners"
              :key="index"
              :xs="24"
              :sm="12"
              :md="8"
              :lg="6"
              :xl="6"
            >
              <a-card class="banner-card" :bordered="true">
                <template #cover>
                  <div class="banner-image-container">
                    <img :src="banner.url" alt="轮播图" class="banner-image" />
                  </div>
                </template>
                <template #actions>
                  <a-popconfirm
                    content="确定要删除这张轮播图吗？"
                    @ok="deleteBanner(banner.id)"
                  >
                    <a-button type="text" status="danger">
                      <template #icon>
                        <icon-delete />
                      </template>
                      删除
                    </a-button>
                  </a-popconfirm>
                </template>
                <a-descriptions :column="1" size="small">
                  <a-descriptions-item label="标题">
                    {{ banner.title || '无标题' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="排序">
                    {{ banner.sort || 0 }}
                  </a-descriptions-item>
                </a-descriptions>
              </a-card>
            </a-col>
          </a-row>
        </div>
        <a-empty v-else description="暂无轮播图" />
      </a-spin>

      <!-- 添加轮播图对话框 -->
      <a-modal
        v-model:visible="addModalVisible"
        title="添加轮播图"
        @ok="handleAddBanner"
        @cancel="resetForm"
        :ok-button-props="{ disabled: !formValid }"
      >
        <a-form :model="formData" layout="vertical">
          <a-form-item
            field="title"
            label="标题"
            :rules="[{ required: true, message: '请输入标题' }]"
          >
            <a-input
              v-model="formData.title"
              placeholder="请输入轮播图标题"
              allow-clear
            />
          </a-form-item>
          <a-form-item
            field="image_url"
            label="轮播图"
            :rules="[{ required: true, message: '请上传轮播图' }]"
          >
            <div class="upload-wrapper">
              <a-upload
                :custom-request="handleImageUpload"
                :show-file-list="false"
                accept="image/*"
              >
                <template #upload-button>
                  <div class="upload-trigger">
                    <img
                      v-if="formData.image_url"
                      :src="formData.image_url"
                      class="preview-image"
                    />
                    <div v-else class="upload-placeholder">
                      <icon-plus />
                      <div class="upload-text">上传轮播图</div>
                    </div>
                  </div>
                </template>
              </a-upload>
            </div>
          </a-form-item>
          <a-form-item field="sort" label="排序">
            <a-input-number
              v-model="formData.sort"
              placeholder="请输入排序值"
              :min="0"
              :max="999"
              style="width: 100%"
            />
          </a-form-item>
        </a-form>
      </a-modal>

      <!-- 操作结果提示 -->
      <a-modal
        v-model:visible="resultModalVisible"
        :title="resultModalTitle"
        @ok="resultModalVisible = false"
        @cancel="resultModalVisible = false"
        :footer="false"
        :mask-closable="true"
      >
        <div class="result-modal-content">
          <a-result
            :status="resultStatus"
            :title="resultTitle"
            :subtitle="resultSubtitle"
          >
            <template #extra>
              <a-button type="primary" @click="resultModalVisible = false">
                确定
              </a-button>
            </template>
          </a-result>
        </div>
      </a-modal>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, onMounted } from 'vue'
import { HomeBannersControllerService } from '../../../generated/services/HomeBannersControllerService'
import { FileControllerService } from '../../../generated/services/FileControllerService'
import type { HomeBannersAddRequest } from '../../../generated/models/HomeBannersAddRequest'
import type { DeleteRequest } from '../../../generated/models/DeleteRequest'
import { Message } from '@arco-design/web-vue'
import type { FileItem } from '@arco-design/web-vue'

// 轮播图数据
interface Banner {
  id: number
  url: string
  title: string
  sort: number
}

const banners = ref<Banner[]>([])
const loading = ref(false)
const addModalVisible = ref(false)
const resultModalVisible = ref(false)
const resultModalTitle = ref('操作结果')
const resultStatus = ref<'success' | 'error' | 'info'>('info')
const resultTitle = ref('')
const resultSubtitle = ref('')

// 表单数据
const formData = reactive<HomeBannersAddRequest>({
  title: '',
  image_url: '',
  sort: 0
})

// 表单验证
const formValid = computed(() => {
  return !!formData.title && !!formData.image_url
})

// 获取轮播图列表
const fetchBanners = async () => {
  try {
    loading.value = true
    const response =
      await HomeBannersControllerService.listHomeBannersUsingGet()

    if (response.code === 0 && response.data) {
      // 处理返回的数据
      const urls = response.data.image_url || []

      // 将URL数组转换为Banner对象数组
      banners.value = urls.map((url, index) => ({
        id: index + 1, // 假设ID是索引+1
        url,
        title: `轮播图${index + 1}`,
        sort: index
      }))
    } else {
      showResult('error', '获取轮播图失败', response.message || '未知错误')
    }
  } catch (error) {
    console.error('获取轮播图失败:', error)
    showResult('error', '获取轮播图失败', '网络错误')
  } finally {
    loading.value = false
  }
}

// 删除轮播图
const deleteBanner = async (id: number) => {
  try {
    loading.value = true
    const deleteRequest: DeleteRequest = { id }
    const response =
      await HomeBannersControllerService.deleteHomeBannerUsingPost(
        deleteRequest
      )

    if (response.code === 0 && response.data) {
      showResult('success', '删除成功', '轮播图已成功删除')
      // 重新获取轮播图列表
      await fetchBanners()

      // 通知其他组件刷新轮播图
      window.dispatchEvent(new CustomEvent('refresh-banners'))

      // 提示用户刷新首页
      Message.info('轮播图已删除，请刷新首页查看效果')
    } else {
      showResult('error', '删除失败', response.message || '未知错误')
    }
  } catch (error) {
    console.error('删除轮播图失败:', error)
    showResult('error', '删除失败', '网络错误')
  } finally {
    loading.value = false
  }
}

// 处理图片上传
const handleImageUpload = async (options: {
  fileItem: FileItem
  onSuccess: (response: any) => void
  onError: (error: any) => void
  onProgress: (percent: number) => void
}) => {
  try {
    options.onProgress(0)
    console.log('开始上传轮播图:', options.fileItem)

    // 确保文件有效
    if (!options.fileItem?.file) {
      throw new Error('无效文件')
    }

    // 调用FileControllerService上传文件
    const response = await FileControllerService.uploadFileUsingPost(
      options.fileItem.file as File,
      'homebanner'
    )

    console.log('上传响应:', response)

    // 处理上传结果
    if (response.code === 0 && response.data?.url) {
      options.onProgress(100) // 上传完成
      options.onSuccess({
        code: 0,
        data: {
          url: response.data.url
        }
      })

      // 更新表单数据中的image_url
      formData.image_url = response.data.url
      Message.success('轮播图上传成功')
    } else {
      const errorMsg = response.message || '上传失败'
      console.error('上传失败:', errorMsg)
      options.onError(new Error(errorMsg))
      Message.error(`轮播图上传失败: ${errorMsg}`)
    }
  } catch (error) {
    console.error('轮播图上传失败:', error)
    options.onError(error)
    Message.error(error instanceof Error ? error.message : '轮播图上传失败')
  }
}

// 显示添加对话框
const showAddModal = () => {
  addModalVisible.value = true
}

// 添加轮播图
const handleAddBanner = async () => {
  try {
    loading.value = true
    const response = await HomeBannersControllerService.addHomeBannerUsingPost(
      formData
    )

    if (response.code === 0) {
      showResult('success', '添加成功', '轮播图已成功添加')
      resetForm()
      addModalVisible.value = false
      // 重新获取轮播图列表
      await fetchBanners()

      // 通知其他组件刷新轮播图
      window.dispatchEvent(new CustomEvent('refresh-banners'))

      // 提示用户刷新首页
      Message.info('轮播图已添加，请刷新首页查看效果')
    } else {
      showResult('error', '添加失败', response.message || '未知错误')
    }
  } catch (error) {
    console.error('添加轮播图失败:', error)
    showResult('error', '添加失败', '网络错误')
  } finally {
    loading.value = false
  }
}

// 重置表单
const resetForm = () => {
  formData.title = ''
  formData.image_url = ''
  formData.sort = 0
}

// 显示结果对话框
const showResult = (
  status: 'success' | 'error' | 'info',
  title: string,
  subtitle: string
) => {
  resultStatus.value = status
  resultTitle.value = title
  resultSubtitle.value = subtitle
  resultModalVisible.value = true
}

// 组件挂载时获取轮播图列表
onMounted(() => {
  fetchBanners()
})
</script>

<style scoped>
.banner-manage-container {
  padding: 20px;
}

.banner-list {
  margin-top: 20px;
}

.banner-card {
  height: 100%;
  transition: all 0.3s;
}

.banner-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.banner-image-container {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-wrapper {
  width: 100%;
}

.upload-trigger {
  width: 100%;
  height: 200px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;
  position: relative;
}

.upload-trigger:hover {
  border-color: #1890ff;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
}

.upload-placeholder :deep(.arco-icon) {
  font-size: 32px;
  margin-bottom: 8px;
}

.upload-text {
  font-size: 16px;
  margin-bottom: 4px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.result-modal-content {
  padding: 20px 0;
}
</style>
