import { createRouter, createWebHistory } from 'vue-router'
import { visitRecordService } from '../services/visitRecordService'
import { routes } from './routes'
import store from '@/store'
import ACCESS_ENUM from '@/access/accessEnum'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
const loginUser = store.state.user.loginUser
// 全局前置守卫
router.beforeEach(async (to, from, next) => {
  if (loginUser == ACCESS_ENUM.NOT_LOGIN || loginUser == ACCESS_ENUM.USER) {
    // 记录页面访问
    await visitRecordService.recordVisit(to.fullPath)
  }
  next()
})

export default router
