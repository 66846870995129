<template>
  <div class="category-tree">
    <a-spin :loading="loading">
      <a-tree
        :data="treeData"
        :show-line="true"
        :selected-keys="selectedKeys"
        @select="handleSelect"
      >
        <template #title="nodeData">
          <div class="tree-node">
            <span class="node-title">{{ nodeData.title }}</span>
          </div>
        </template>
      </a-tree>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  onMounted,
  watch,
  defineProps,
  defineEmits,
  defineExpose,
  withDefaults
} from 'vue'
import { Message } from '@arco-design/web-vue'
import { ProductCategoryControllerService } from '../../../../generated'
import type { CategoryTreeVO } from '../../../../generated/models/CategoryTreeVO'

interface Props {
  selectedId?: number
}

const props = withDefaults(defineProps<Props>(), {
  selectedId: undefined
})

const emit = defineEmits<{
  (e: 'update:selectedId', id: number): void
  (e: 'node-click', node: CategoryTreeVO): void
}>()

// 树形数据
const treeData = ref<CategoryTreeVO[]>([])
const loading = ref(false)
const selectedKeys = ref<string[]>([])

// 监听选中ID变化
watch(
  () => props.selectedId,
  (newId) => {
    if (newId) {
      selectedKeys.value = [String(newId)]
    } else {
      selectedKeys.value = []
    }
  }
)

// 加载分类树数据
const loadCategoryTree = async () => {
  loading.value = true
  try {
    const response =
      await ProductCategoryControllerService.getCategoryTreeUsingGet()
    if (response.data) {
      // 转换数据格式
      treeData.value = convertToTreeData(response.data)
    }
  } catch (error) {
    console.error('加载分类树失败:', error)
    Message.error('加载分类树失败')
  } finally {
    loading.value = false
  }
}

// 转换数据为树形格式
const convertToTreeData = (categories: CategoryTreeVO[]): any[] => {
  return categories.map((category) => ({
    key: category.id,
    title: category.name,
    children: category.children ? convertToTreeData(category.children) : [],
    dataRef: category
  }))
}

// 处理节点选择
const handleSelect = (selectedKeys: string[], node: any) => {
  if (selectedKeys.length > 0 && node.selectedNodes?.[0]) {
    const id = Number(selectedKeys[0])
    const nodeData = node.selectedNodes[0].dataRef
    emit('update:selectedId', id)
    emit('node-click', nodeData)
  }
}

// 暴露刷新方法
defineExpose({
  refreshTree: loadCategoryTree
})

// 初始加载
onMounted(() => {
  loadCategoryTree()
})
</script>

<style scoped>
.category-tree {
  height: 100%;
}

.tree-node {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.node-title {
  cursor: pointer;
}

:deep(.arco-tree-node-title:hover) {
  background-color: var(--color-fill-2);
}

:deep(.arco-tree-node-selected .arco-tree-node-title) {
  background-color: var(--color-primary-light-1);
  color: var(--color-primary);
}
</style>
