<template>
  <div class="company-info-manage">
    <div class="page-header">
      <h2>公司信息管理</h2>
    </div>

    <div class="content-container">
      <!-- 左侧表单区域 -->
      <div class="form-section">
        <a-spin :loading="loading">
          <a-form
            ref="formRef"
            :model="formData"
            @submit="handleSubmit"
            class="company-form"
            :rules="rules"
          >
            <a-form-item
              field="company_name"
              label="公司名称"
              validate-trigger="blur"
            >
              <a-input
                v-model="formData.company_name"
                placeholder="请输入公司名称"
                allow-clear
              />
            </a-form-item>

            <a-form-item field="company_desc" label="公司简介">
              <a-textarea
                v-model="formData.company_desc"
                placeholder="请输入公司简介"
                :auto-size="{ minRows: 3, maxRows: 6 }"
                allow-clear
              />
            </a-form-item>

            <a-form-item field="phone" label="联系电话">
              <a-input
                v-model="formData.phone"
                placeholder="请输入联系电话"
                allow-clear
              />
            </a-form-item>

            <a-form-item field="email" label="电子邮箱">
              <a-input
                v-model="formData.email"
                placeholder="请输入电子邮箱"
                allow-clear
              />
            </a-form-item>

            <a-form-item field="address" label="公司地址">
              <a-textarea
                v-model="formData.address"
                placeholder="请输入公司地址"
                :auto-size="{ minRows: 2, maxRows: 4 }"
                allow-clear
              />
            </a-form-item>

            <a-form-item field="production_base" label="生产基地">
              <a-textarea
                v-model="formData.production_base"
                placeholder="请输入生产基地信息"
                :auto-size="{ minRows: 2, maxRows: 4 }"
                allow-clear
              />
            </a-form-item>

            <a-form-item field="wechat" label="微信号">
              <a-input
                v-model="formData.wechat"
                placeholder="请输入微信号"
                allow-clear
              />
            </a-form-item>

            <a-form-item>
              <a-space>
                <a-button type="primary" html-type="submit">
                  保存修改
                </a-button>
                <a-button @click="resetForm"> 重置 </a-button>
              </a-space>
            </a-form-item>
          </a-form>
        </a-spin>
      </div>

      <!-- 右侧预览区域 -->
      <div class="preview-section">
        <div class="preview-card">
          <h3 class="preview-title">品牌资源</h3>

          <div class="resource-item">
            <div class="resource-label">公司Logo</div>
            <div class="resource-content">
              <div class="upload-wrapper">
                <a-upload
                  :custom-request="handleLogoUpload"
                  :show-file-list="false"
                  accept="image/*"
                >
                  <template #upload-button>
                    <div class="upload-trigger">
                      <img
                        v-if="formData.logo_url"
                        :src="formData.logo_url"
                        class="preview-image"
                      />
                      <div v-else class="upload-placeholder">
                        <icon-plus />
                        <div class="upload-text">上传Logo</div>
                      </div>
                    </div>
                  </template>
                </a-upload>
              </div>
              <div class="resource-tip">建议尺寸: 200px × 200px，PNG格式</div>
            </div>
          </div>

          <div class="resource-item">
            <div class="resource-label">微信二维码</div>
            <div class="resource-content">
              <div class="upload-wrapper">
                <a-upload
                  :custom-request="handleQrcodeUpload"
                  :show-file-list="false"
                  accept="image/*"
                >
                  <template #upload-button>
                    <div class="upload-trigger">
                      <img
                        v-if="formData.wechat_qrcode"
                        :src="formData.wechat_qrcode"
                        class="preview-image"
                      />
                      <div v-else class="upload-placeholder">
                        <icon-plus />
                        <div class="upload-text">上传二维码</div>
                      </div>
                    </div>
                  </template>
                </a-upload>
              </div>
              <div class="resource-tip">
                建议尺寸: 300px × 300px，清晰可扫描
              </div>
            </div>
          </div>

          <div class="preview-info">
            <h4>预览效果</h4>
            <p>以下是客户端展示效果的预览</p>

            <div class="preview-card-container">
              <div class="preview-company-card">
                <div class="preview-company-header">
                  <img
                    v-if="formData.logo_url"
                    :src="formData.logo_url"
                    class="preview-company-logo"
                  />
                  <div v-else class="preview-company-logo-placeholder"></div>
                  <div class="preview-company-info">
                    <div class="preview-company-name">
                      {{ formData.company_name || '公司名称' }}
                    </div>
                    <div class="preview-company-contact">
                      <span v-if="formData.phone"
                        >电话: {{ formData.phone }}</span
                      >
                      <span v-if="formData.email"
                        >邮箱: {{ formData.email }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="preview-company-desc">
                  {{ formData.company_desc || '公司简介将显示在这里...' }}
                </div>
                <div class="preview-company-footer">
                  <div class="preview-qrcode-container">
                    <img
                      v-if="formData.wechat_qrcode"
                      :src="formData.wechat_qrcode"
                      class="preview-qrcode"
                    />
                    <div v-else class="preview-qrcode-placeholder"></div>
                    <div class="preview-qrcode-text">微信咨询</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue'
import { FileItem, Message } from '@arco-design/web-vue'
import {
  CompanyInfoControllerService,
  FileControllerService
} from '../../../generated'
import type { CompanyInfoVo } from '../../../generated'
import type { CompanyInfoUpdateRequest } from '../../../generated'
import { IconPlus } from '@arco-design/web-vue/es/icon'

const loading = ref(false)
const formRef = ref()
const formData = ref<CompanyInfoUpdateRequest>({
  company_name: '',
  company_desc: '',
  phone: '',
  email: '',
  address: '',
  production_base: '',
  wechat: '',
  logo_url: '',
  wechat_qrcode: ''
})

// 表单验证规则
const rules = reactive({
  company_name: [
    { required: true, message: '请输入公司名称' },
    { maxLength: 50, message: '公司名称不能超过50个字符' }
  ],
  email: [
    {
      match: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
      message: '请输入正确的邮箱格式'
    }
  ],
  phone: [
    {
      match: /^1[3-9]\d{9}$/,
      message: '请输入正确的手机号码'
    }
  ]
})

// 获取公司信息
const fetchCompanyInfo = async () => {
  loading.value = true
  try {
    const response = await CompanyInfoControllerService.getCompanyInfoUsingGet()
    if (response.data) {
      // 将获取到的数据填充到表单中
      Object.assign(formData.value, response.data)
    }
  } catch (error) {
    console.error('获取公司信息失败:', error)
    Message.error('获取公司信息失败')
  } finally {
    loading.value = false
  }
}

// 处理表单提交
const handleSubmit = async () => {
  const { validate, getFieldsError } = formRef.value
  try {
    await validate()
    loading.value = true
    const response =
      await CompanyInfoControllerService.updateCompanyInfoUsingPost(
        formData.value
      )
    if (response.code === 0) {
      Message.success('保存成功')
    } else {
      Message.error(response.message || '保存失败')
    }
  } catch (error) {
    console.error('更新公司信息失败:', error)
    const errors = getFieldsError()
    if (errors && Object.keys(errors).length > 0) {
      // 表单验证错误
      Message.error('表单填写有误，请检查')
    } else {
      // API错误
      Message.error('更新失败')
    }
  } finally {
    loading.value = false
  }
}

// 重置表单
const resetForm = () => {
  formRef.value?.resetFields()
  fetchCompanyInfo() // 重新获取原始数据
}

// 处理Logo上传
const handleLogoUpload = async (options: {
  fileItem: FileItem
  onSuccess: (response: any) => void
  onError: (error: any) => void
  onProgress: (percent: number) => void
}) => {
  try {
    options.onProgress(0)
    console.log('开始上传文件:', options.fileItem)
    //确保文件有效
    if (!options.fileItem?.file) {
      throw new Error('无效文件')
    }
    // 调用FileControllerService上传文件
    const response = await FileControllerService.uploadFileUsingPost(
      options.fileItem.file as File,
      'logo'
    )
    console.log('上传响应:', response)
    // 处理上传结果
    if (response.code === 0 && response.data?.url) {
      options.onProgress(100) // 上传完成
      options.onSuccess({
        code: 0,
        data: {
          url: response.data.url
        }
      })
      // 更新表单数据中的logo_url
      formData.value.logo_url = response.data.url
      Message.success('Logo上传成功')
    } else {
      const errorMsg = response.message || '上传失败'
      console.error('上传失败:', errorMsg)
      options.onError(new Error(errorMsg))
      Message.error(`logo上传失败: ${errorMsg}`)
    }
  } catch (error) {
    console.error('Logo上传失败s:', error)
    options.onError(error)
    Message.error(error instanceof Error ? error.message : 'logo上传失败')
  }
}

// 处理二维码上传
const handleQrcodeUpload = async (options: {
  fileItem: FileItem
  onSuccess: (response: any) => void
  onError: (error: any) => void
  onProgress: (percent: number) => void
}) => {
  try {
    options.onProgress(0)
    console.log('开始上传文件:', options.fileItem)
    //确保文件有效
    if (!options.fileItem?.file) {
      throw new Error('无效文件')
    }
    // 调用FileControllerService上传文件
    const response = await FileControllerService.uploadFileUsingPost(
      options.fileItem.file as File,
      'qrcode'
    )
    console.log('上传响应:', response)
    // 处理上传结果
    if (response.code === 0 && response.data?.url) {
      options.onProgress(100) // 上传完成
      options.onSuccess({
        code: 0,
        data: {
          url: response.data.url
        }
      })
      // 更新表单数据中的logo_url
      formData.value.wechat_qrcode = response.data.url
      Message.success('二维码上传成功')
    } else {
      const errorMsg = response.message || '上传失败'
      console.error('上传失败:', errorMsg)
      options.onError(new Error(errorMsg))
      Message.error(`二维码上传失败: ${errorMsg}`)
    }
  } catch (error) {
    console.error('二维码上传失败s:', error)
    options.onError(error)
    Message.error(error instanceof Error ? error.message : '二维码上传失败')
  }
}
onMounted(() => {
  fetchCompanyInfo()
})
</script>

<style scoped>
.company-info-manage {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  margin-bottom: 24px;
}

.page-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: var(--color-text-1);
}

.page-description {
  margin-top: 8px;
  color: var(--color-text-3);
}

.content-container {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  background-color: var(--color-bg-2);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  padding: 24px;
}

.form-section {
  flex: 1;
  min-width: 0;
  max-width: calc(100% - 424px);
}

.preview-section {
  width: 400px;
  flex-shrink: 0;
}

.company-form {
  background-color: var(--color-bg-2);
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.preview-card {
  background-color: var(--color-bg-2);
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  border-left: 1px solid var(--color-border-2);
  padding-left: 24px;
}

.preview-title {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text-1);
}

.resource-item {
  margin-bottom: 24px;
}

.resource-label {
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--color-text-2);
}

.resource-tip {
  margin-top: 8px;
  font-size: 12px;
  color: var(--color-text-3);
}

.upload-wrapper {
  width: 100%;
}

.upload-trigger {
  width: 200px;
  height: 200px;
  border: 1px dashed var(--color-border-2);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.upload-trigger:hover {
  border-color: rgb(var(--primary-6));
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-placeholder {
  text-align: center;
  color: var(--color-text-3);
}

.upload-placeholder :deep(.arco-icon) {
  font-size: 24px;
  margin-bottom: 8px;
}

.upload-text {
  font-size: 14px;
}

.preview-info {
  margin-top: 32px;
  border-top: 1px solid var(--color-border-2);
  padding-top: 24px;
}

.preview-info h4 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}

.preview-info p {
  margin-top: 0;
  margin-bottom: 16px;
  color: var(--color-text-3);
  font-size: 14px;
}

.preview-card-container {
  margin-top: 16px;
}

.preview-company-card {
  border: 1px solid var(--color-border-2);
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
}

.preview-company-header {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid var(--color-border-2);
}

.preview-company-logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.preview-company-logo-placeholder {
  width: 60px;
  height: 60px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.preview-company-info {
  margin-left: 16px;
  flex: 1;
  min-width: 0;
}

.preview-company-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--color-text-1);
}

.preview-company-contact {
  font-size: 12px;
  color: var(--color-text-3);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.preview-company-desc {
  padding: 16px;
  font-size: 14px;
  color: var(--color-text-2);
  line-height: 1.6;
  max-height: 100px;
  overflow-y: auto;
}

.preview-company-footer {
  padding: 16px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: flex-end;
}

.preview-qrcode-container {
  text-align: center;
}

.preview-qrcode {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.preview-qrcode-placeholder {
  width: 80px;
  height: 80px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.preview-qrcode-text {
  margin-top: 8px;
  font-size: 12px;
  color: var(--color-text-2);
}

/* 响应式布局 */
@media screen and (max-width: 992px) {
  .content-container {
    flex-direction: column;
    padding: 20px;
  }

  .form-section {
    max-width: 100%;
  }

  .preview-section {
    width: 100%;
    margin-top: 24px;
  }

  .preview-card {
    border-left: none;
    border-top: 1px solid var(--color-border-2);
    padding-left: 0;
    padding-top: 24px;
  }
}
</style>
