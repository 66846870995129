/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_'
import type { BaseResponse_CompanyInfoVo_ } from '../models/BaseResponse_CompanyInfoVo_'
import type { CompanyInfoUpdateRequest } from '../models/CompanyInfoUpdateRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class CompanyInfoControllerService {
  /**
   * getCompanyInfo
   * @returns BaseResponse_CompanyInfoVo_ OK
   * @throws ApiError
   */
  public static getCompanyInfoUsingGet(): CancelablePromise<BaseResponse_CompanyInfoVo_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/company/get',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * updateCompanyInfo
   * @param companyInfoUpdateRequest companyInfoUpdateRequest
   * @returns BaseResponse_boolean_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static updateCompanyInfoUsingPost(
    companyInfoUpdateRequest: CompanyInfoUpdateRequest
  ): CancelablePromise<BaseResponse_boolean_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/company/update',
      body: companyInfoUpdateRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
}
