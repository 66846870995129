<template>
  <div class="company-introduction">
    <h1>公司介绍</h1>
    <a-spin :loading="loading">
      <p v-if="companyInfo?.company_desc">
        {{ companyInfo.company_desc }}
      </p>
      <p v-else>暂无公司介绍信息</p>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Message } from '@arco-design/web-vue'
import { CompanyInfoControllerService } from '../../../generated'
import type { CompanyInfoVo } from '../../../generated'

// 公司信息
const companyInfo = ref<CompanyInfoVo | null>(null)
const loading = ref(false)

// 获取公司信息
const fetchCompanyInfo = async () => {
  loading.value = true
  try {
    const response = await CompanyInfoControllerService.getCompanyInfoUsingGet()
    if (response.data) {
      companyInfo.value = response.data
    }
  } catch (error) {
    console.error('获取公司信息失败:', error)
    Message.error('获取公司信息失败')
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchCompanyInfo()
})
</script>

<style scoped>
.company-introduction {
  padding: 60px;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: 48px;
  margin-bottom: 30px;
}

p {
  font-size: 20px;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  white-space: pre-line; /* 保留换行符 */
  text-align: left;
}
</style>
