/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_long_ } from '../models/BaseResponse_long_'
import type { BaseResponse_Page_ProductMaterial_ } from '../models/BaseResponse_Page_ProductMaterial_'
import type { ProductMaterialAddRequest } from '../models/ProductMaterialAddRequest'
import type { ProductMaterialQueryRequest } from '../models/ProductMaterialQueryRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class ProductMaterialControllerService {
  /**
   * addProductMaterial
   * @param productMaterialAddRequest productMaterialAddRequest
   * @returns BaseResponse_long_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static addProductMaterialUsingPost(
    productMaterialAddRequest: ProductMaterialAddRequest
  ): CancelablePromise<BaseResponse_long_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/productMaterial/add',
      body: productMaterialAddRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
  /**
   * listProductInfoByPage
   * @param productMaterialQueryRequest productMaterialQueryRequest
   * @returns BaseResponse_Page_ProductMaterial_ OK
   * @returns any Created
   * @throws ApiError
   */
  public static listProductInfoByPageUsingPost1(
    productMaterialQueryRequest: ProductMaterialQueryRequest
  ): CancelablePromise<BaseResponse_Page_ProductMaterial_ | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/productMaterial/list/page',
      body: productMaterialQueryRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`
      }
    })
  }
}
