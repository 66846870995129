import { RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotAuthView from '@/views/NotAuthView.vue'
import AddProductView from '@/views/product/AddProductView.vue'
import AdminView from '@/views/AdminView.vue'
import HideMenu from '@/views/HideMenu.vue'
import UserLoginView from '@/views/user/UserLoginView.vue'
import ProductDetailView from '@/views/product/ProductDetailView.vue'
import ProductListView from '@/views/product/ProductListView.vue'
import UserRegisterView from '@/views/user/UserRegisterView.vue'
import UserLayout from '@/layout/UserLayOut.vue'
import ACCESS_ENUM from '@/access/accessEnum'
import ContactUsView from '@/views/product/ContactUsView.vue'
import AddMaterialView from '@/views/material/AddMaterialView.vue'
import MaterialListView from '@/views/material/MaterialListView.vue'
import CompanyIntroduction from '@/views/company/CompanyIntroduction.vue'
import CompanyInfoManage from '@/views/company/CompanyInfoManage.vue'
import VisitStatistics from '@/views/visitrecord/VisitStatistics.vue'
import HomeBannerManage from '@/views/homebanner/HomeBannerManage.vue'
import CategoryManageView from '@/views/productcategory/CategoryManageView.vue'
import ProductCenter from '@/views/product/ProductCenter.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/user',
    name: '用户',
    component: UserLayout,
    meta: {
      hideInMenu: 'true'
    },
    children: [
      {
        path: 'login',
        name: '用户登录',
        component: UserLoginView
      },
      {
        path: 'register',
        name: '用户注册',
        component: UserRegisterView
      }
    ]
  },
  {
    path: '/',
    name: '主页',
    component: HomeView
  },
  {
    path: '/productcenter',
    name: '产品中心',
    component: ProductCenter
  },
  {
    path: '/notAuth',
    name: '无权限',
    component: NotAuthView,
    meta: {
      hideInMenu: 'true'
    }
  },
  {
    path: '/hideMenu',
    name: 'hideMenu',
    component: HideMenu,
    meta: {
      hideInMenu: 'true'
    }
  },
  {
    path: '/product/detail/:id',
    name: 'ProductDetail',
    component: ProductDetailView,
    meta: {
      hideInMenu: 'true'
    }
  },
  {
    path: '/company',
    name: '公司介绍',
    component: CompanyIntroduction
  },
  {
    path: '/materials',
    name: '资料列表',
    component: MaterialListView
  },
  {
    path: '/contactus',
    name: '联系我们',
    component: ContactUsView
  },
  {
    path: '/add/product',
    name: '添加产品',
    component: AddProductView,
    meta: {
      access: ACCESS_ENUM.ADMIN
    }
  },
  {
    path: '/manager/product',
    name: '管理产品',
    component: ProductListView,
    meta: {
      access: ACCESS_ENUM.ADMIN
    }
  },
  {
    path: '/admin',
    name: '管理员访问',
    component: AdminView,
    meta: {
      access: ACCESS_ENUM.ADMIN
    }
  },
  {
    path: '/add/material',
    name: '添加资料',
    component: AddMaterialView,
    meta: {
      access: ACCESS_ENUM.ADMIN
    }
  },
  {
    path: '/company/manage',
    name: '公司信息管理',
    component: CompanyInfoManage,
    meta: {
      access: ACCESS_ENUM.ADMIN
    }
  },
  {
    path: '/visit/statistics',
    name: '访问统计',
    component: VisitStatistics,
    meta: {
      access: ACCESS_ENUM.ADMIN
    }
  },
  {
    path: '/banner/manage',
    name: '轮播图管理',
    component: HomeBannerManage,
    meta: {
      access: ACCESS_ENUM.ADMIN
    }
  },
  {
    path: '/productcategory/manage',
    name: '产品分类管理',
    component: CategoryManageView,
    meta: {
      access: ACCESS_ENUM.ADMIN
    }
  }
]
