import { StoreOptions } from 'vuex'
import ACCESS_ENUM from '@/access/accessEnum'
import { UserControllerService } from '../../generated'

export default {
  namespaced: true,
  state: () => ({
    loginUser: {
      userName: '未登录'
    }
  }),
  getters: {
    getLoginUser: (state) => state.loginUser
  },
  actions: {
    async getLoginUser({ commit, state }, payload) {
      try {
        // 添加详细的日志记录
        console.log('开始获取登录用户信息')
        const res = await UserControllerService.getLoginUserUsingGet()
        console.log('获取登录用户信息响应:', JSON.stringify(res))

        if (res.code === 0 && res.data) {
          console.log('登录成功，用户信息:', JSON.stringify(res.data))
          commit('updateUser', res.data)
          return res.data
        } else {
          console.log('未登录或登录失败:', res.message)
          commit('updateUser', {
            ...state.loginUser,
            userRole: ACCESS_ENUM.NOT_LOGIN
          })
          return null
        }
      } catch (error) {
        console.error('获取登录用户信息出错:', error)
        commit('updateUser', {
          ...state.loginUser,
          userRole: ACCESS_ENUM.NOT_LOGIN
        })
        return null
      }
    }
  },
  mutations: {
    updateUser(state, payload) {
      state.loginUser = payload
    }
  }
} as StoreOptions<any>
