<template>
  <div class="category-detail">
    <!-- 分类路径 -->
    <a-breadcrumb class="category-path">
      <a-breadcrumb-item v-for="item in categoryPath" :key="item.id">
        {{ item.name }}
      </a-breadcrumb-item>
    </a-breadcrumb>

    <!-- 子分类列表 -->
    <a-card class="sub-categories" title="子分类">
      <template #extra>
        <a-button type="primary" @click="handleAddSubCategory">
          添加子分类
        </a-button>
      </template>
      <a-spin :loading="loadingChildren">
        <div v-if="children.length > 0" class="sub-category-list">
          <a-card
            v-for="child in children"
            :key="child.id"
            :title="child.name"
            hoverable
            class="sub-category-item"
          >
            <template #extra>
              <a-space>
                <a-button size="small" @click="handleEditCategory(child)">
                  编辑
                </a-button>
                <a-button
                  size="small"
                  status="danger"
                  @click="handleDeleteCategory(child)"
                >
                  删除
                </a-button>
              </a-space>
            </template>
            <p>子分类数：{{ child.children?.length || 0 }}</p>
          </a-card>
        </div>
        <a-empty v-else description="暂无子分类" />
      </a-spin>
    </a-card>

    <!-- 关联产品列表 -->
    <a-card class="products" title="关联产品">
      <template #extra>
        <a-space>
          <a-input-search
            v-model="searchText"
            placeholder="搜索产品"
            @search="handleSearch"
          />
          <a-button type="primary" @click="handleBatchSetCategories">
            批量设置分类
          </a-button>
        </a-space>
      </template>
      <a-table
        :loading="loadingProducts"
        :data="products"
        :pagination="pagination"
        @page-change="handlePageChange"
      >
        <template #columns>
          <a-table-column title="产品名称" data-index="name" />
          <a-table-column title="操作" align="center">
            <template #cell="{ record }">
              <a-space>
                <a-button size="small" @click="handleViewProduct(record)">
                  查看
                </a-button>
                <a-button
                  size="small"
                  status="danger"
                  @click="handleRemoveProduct(record)"
                >
                  移除
                </a-button>
              </a-space>
            </template>
          </a-table-column>
        </template>
      </a-table>
    </a-card>

    <!-- 分类表单弹窗 -->
    <category-form-modal
      v-model:visible="categoryFormVisible"
      :parent-id="categoryFormParentId"
      :edit-data="categoryFormEditData"
      @success="handleFormSuccess"
    />

    <!-- 搜索产品对话框 -->
    <a-modal
      v-model:visible="searchProductVisible"
      title="搜索产品"
      :confirm-loading="searchProductLoading"
      @ok="handleAddProducts"
    >
      <a-input-search
        v-model="searchText"
        placeholder="搜索产品"
        @search="handleSearchProduct"
      />
      <a-table
        :loading="searchProductLoading"
        :data="searchProductList"
        :pagination="pagination"
        @page-change="handlePageChange"
      >
        <template #columns>
          <a-table-column title="产品名称" data-index="name" />
          <a-table-column title="操作" align="center">
            <template #cell="{ record }">
              <a-space>
                <a-checkbox
                  :model-value="selectedProductIds.includes(record.id || 0)"
                  @change="
                    (checked) => handleSelectProduct(record.id || 0, checked)
                  "
                />
              </a-space>
            </template>
          </a-table-column>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits, withDefaults } from 'vue'
import { Message } from '@arco-design/web-vue'
import {
  ProductCategoryControllerService,
  ProductInfoControllerService
} from '../../../../generated'
import type { ProductCategory } from '../../../../generated/models/ProductCategory'
import type { CategoryTreeVO } from '../../../../generated/models/CategoryTreeVO'
import type { ProductInfoVO } from '../../../../generated/models/ProductInfoVO'
import type { CategoryProductRequest } from '../../../../generated/models/CategoryProductRequest'
import type { ProductInfoQueryRequest } from '../../../../generated/models/ProductInfoQueryRequest'
import CategoryFormModal from './CategoryFormModal.vue'
import CategoryTree from './CategoryTree.vue'

const props = withDefaults(
  defineProps<{
    categoryId: number
  }>(),
  {}
)

const emit = defineEmits<{
  (e: 'refresh-tree'): void
}>()

// 分类路径
const categoryPath = ref<ProductCategory[]>([])
const loadingPath = ref(false)

// 子分类
const children = ref<CategoryTreeVO[]>([])
const loadingChildren = ref(false)

// 关联产品
const products = ref<ProductInfoVO[]>([])
const loadingProducts = ref(false)
const searchText = ref('')
const pagination = ref({
  total: 0,
  current: 1,
  pageSize: 10
})

// 分类表单弹窗
const categoryFormVisible = ref(false)
const categoryFormEditData = ref<CategoryTreeVO | null>(null)
const categoryFormParentId = ref<number>()
const selectedCategoryId = ref<number>()

// 搜索产品对话框
const searchProductVisible = ref(false)
const searchProductLoading = ref(false)
const searchProductList = ref<ProductInfoVO[]>([])
const selectedProductIds = ref<number[]>([])

// 加载分类路径
const loadCategoryPath = async () => {
  loadingPath.value = true
  try {
    const response =
      await ProductCategoryControllerService.getCategoryPathUsingGet(
        props.categoryId
      )
    if (response.data) {
      categoryPath.value = response.data
    }
  } catch (error) {
    console.error('加载分类路径失败:', error)
    Message.error('加载分类路径失败')
  } finally {
    loadingPath.value = false
  }
}

// 加载子分类
const loadChildren = async () => {
  loadingChildren.value = true
  try {
    const response =
      await ProductCategoryControllerService.getAllChildrenUsingGet(
        props.categoryId
      )
    if (response.data) {
      children.value = response.data
    }
  } catch (error) {
    console.error('加载子分类失败:', error)
    Message.error('加载子分类失败')
  } finally {
    loadingChildren.value = false
  }
}

// 加载关联产品
const loadProducts = async () => {
  loadingProducts.value = true
  try {
    const response =
      await ProductCategoryControllerService.getProductsByCategoryUsingGet(
        props.categoryId
      )
    if (response.data) {
      products.value = response.data
    }
  } catch (error) {
    console.error('加载关联产品失败:', error)
    Message.error('加载关联产品失败')
  } finally {
    loadingProducts.value = false
  }
}

// 处理添加子分类
const handleAddSubCategory = () => {
  categoryFormParentId.value = props.categoryId
  categoryFormEditData.value = null
  categoryFormVisible.value = true
}

// 处理编辑分类
const handleEditCategory = (category: CategoryTreeVO) => {
  categoryFormParentId.value = category.id
  categoryFormEditData.value = category
  categoryFormVisible.value = true
}

// 处理删除分类
const handleDeleteCategory = async (category: CategoryTreeVO) => {
  try {
    const response =
      await ProductCategoryControllerService.deleteCategoryUsingPost({
        id: category.id
      })
    if (response.data) {
      Message.success('删除成功')
      await loadChildren()
      emit('refresh-tree')
    }
  } catch (error) {
    console.error('删除分类失败:', error)
    Message.error('删除分类失败')
  }
}

// 处理表单提交成功
const handleFormSuccess = async () => {
  Message.success('操作成功')
  await loadChildren()
  emit('refresh-tree')
}

// 处理搜索
const handleSearch = (value: string) => {
  searchText.value = value
  pagination.value.current = 1
  loadProducts()
}

// 处理分页
const handlePageChange = (page: number) => {
  pagination.value.current = page
  loadProducts()
}

// 处理查看产品
const handleViewProduct = (product: ProductInfoVO) => {
  window.open(`/product/detail/${product.id}`, '_blank')
}

// 处理搜索产品
const handleSearchProduct = async () => {
  searchProductLoading.value = true
  try {
    const request: ProductInfoQueryRequest = {
      name: searchText.value,
      current: 1,
      pageSize: 10
    }
    const response =
      await ProductInfoControllerService.listSimpleProductVoByPageUsingPost(
        request
      )
    if (response.data?.records) {
      searchProductList.value = response.data.records
    }
  } catch (error) {
    console.error('搜索产品失败:', error)
    Message.error('搜索产品失败')
  } finally {
    searchProductLoading.value = false
  }
}

// 处理选择产品
const handleSelectProduct = (productId: number, checked: boolean) => {
  if (checked) {
    selectedProductIds.value.push(productId)
  } else {
    const index = selectedProductIds.value.indexOf(productId)
    if (index > -1) {
      selectedProductIds.value.splice(index, 1)
    }
  }
}

// 添加产品到分类
const handleAddProducts = async () => {
  if (selectedProductIds.value.length === 0) {
    Message.warning('请选择要添加的产品')
    return
  }

  try {
    const request: CategoryProductRequest = {
      categoryId: props.categoryId,
      productIds: selectedProductIds.value
    }
    const response =
      await ProductCategoryControllerService.addProductsToCategoryUsingPost(
        request
      )
    if (response.data) {
      Message.success('添加成功')
      searchProductVisible.value = false
      selectedProductIds.value = []
      await loadProducts()
    }
  } catch (error) {
    console.error('添加产品失败:', error)
    Message.error('添加产品失败')
  }
}

// 从分类移除产品
const handleRemoveProduct = async (product: ProductInfoVO) => {
  if (!product.id) {
    Message.error('产品ID不存在')
    return
  }

  try {
    const request: CategoryProductRequest = {
      categoryId: props.categoryId,
      productIds: [product.id]
    }
    const response =
      await ProductCategoryControllerService.removeProductsFromCategoryUsingPost(
        request
      )
    if (response.data) {
      Message.success('移除成功')
      await loadProducts()
    }
  } catch (error) {
    console.error('移除产品失败:', error)
    Message.error('移除产品失败')
  }
}

// 批量设置分类
const handleBatchSetCategories = () => {
  searchProductVisible.value = true
  searchProductList.value = []
  selectedProductIds.value = []
  searchText.value = ''
}

// 监听分类ID变化
watch(
  () => props.categoryId,
  () => {
    if (props.categoryId) {
      loadCategoryPath()
      loadChildren()
      loadProducts()
    }
  },
  { immediate: true }
)

// 处理节点点击
const handleNodeClick = (node: CategoryTreeVO) => {
  selectedCategoryId.value = node.id
}

// 处理编辑节点
const handleEdit = (node: CategoryTreeVO) => {
  categoryFormParentId.value = node.id
  categoryFormEditData.value = node
  categoryFormVisible.value = true
}
</script>

<style scoped>
.category-detail {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.category-path {
  margin-bottom: 16px;
}

.sub-category-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.sub-category-item {
  height: 100%;
}
</style>
